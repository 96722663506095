<div
  class="border-gray-200 bg-white px-4 py-5 sm:px-6"
  [class.border-b]="borderBottom"
>
  <div class="-ml-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
    <mat-icon
      [fontIcon]="icon"
      [class]="disabled ? 'text-gray-300' : 'text-black'"
      *ngIf="icon"
    ></mat-icon>

    <div class="ml-4">
      <h3
        class="text-sm font-normal leading-6"
        [ngClass]="disabled ? 'text-gray-300' : 'text-gray-900'"
      >
        {{ heading }}
      </h3>
      <p class="mt-1 text-sm text-gray-500" *ngIf="subheading">
        {{ subheading }}
      </p>
    </div>

    <div class="ml-auto flex-shrink-0">
      <button
        mat-flat-button
        color="primary"
        [attr.data-cy]="dataCy || 'wide-card-btn'"
        type="button"
        [disabled]="disabled"
        (click)="handleClick()"
        class="text-white"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</div>
