import { MonitorType } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import {
  Alert,
  InsertEmailRecipientsDTO,
  Monitor,
  PortalNotification,
  Recipient,
  RecipientGroup,
  RecipientGroupType,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const loadBom = createAction('[Alerting/API] Load Monitors');

export const fetchMonitors = createAction(
  '[Alerting/API] Fetch All Monitors',
  props<{ monitorType?: MonitorType }>(),
);

export const fetchMonitorsSuccess = createAction(
  '[Alerting/API] Fetch All Monitors Success',
  props<{ result: Monitor[] }>(),
);

export const fetchMonitorById = createAction(
  '[Alerting/API] Fetch Monitor by Id',
  props<{ id: string }>(),
);

export const fetchMonitorByIdSuccess = createAction(
  '[Alerting/API] Fetch Monitor by Id Success',
  props<{ result: Monitor }>(),
);

export const upsertMonitor = createAction(
  '[Alerting/API] Upsert Monitor',
  props<{ monitor: Monitor }>(),
);

export const upsertMonitorSuccess = createAction(
  '[Alerting/API] Upsert Monitor Success',
  props<{ monitorType?: MonitorType }>(),
);

export const deleteMonitor = createAction(
  '[Alerting/API] Delete Monitor',
  props<{ monitorId: string }>(),
);

export const deleteMonitorSuccess = createAction(
  '[Alerting/API] Delete Monitor Success',
);

export const executeMonitor = createAction(
  '[Alerting/API] Execute Monitor',
  props<{ monitorId: string }>(),
);

export const executeMonitorSuccess = createAction(
  '[Alerting/API] Execute Monitor Success',
);

export const fetchReceiversGroup = createAction(
  '[Alerting/API] Fetch Recipient Groups',
);

export const fetchRecipientGroupsSuccess = createAction(
  '[Alerting/API] Fetch All Recipient Groups Success',
  props<{ result: RecipientGroup[] }>(),
);

export const fetchApproversGroups = createAction(
  '[Alerting/API] Fetch Recipient Groups',
);

export const fetchApproversGroupsSuccess = createAction(
  '[Alerting/API] Fetch All Approvers Groups Success',
  props<{ result: RecipientGroup[] }>(),
);

export const fetchReceiversFromGroup = createAction(
  '[Alerting/API] Fetch Recipient from Groups',
  props<{ id: string }>(),
);

export const fetchReceiversFromGroupSuccess = createAction(
  '[Alerting/API] Fetch All Receivers from Group Success',
  props<{ result: Recipient[] }>(),
);

export const fetchApproversFromGroup = createAction(
  '[Alerting/API] Fetch Approvers from Groups',
  props<{ id: string }>(),
);

export const fetchApproversFromGroupSuccess = createAction(
  '[Alerting/API] Fetch All Approvers from Group Success',
  props<{ result: Recipient[] }>(),
);

export const upsertRecipientEmails = createAction(
  '[Alerting/API] Insert Email into Group',
  props<{ dto: InsertEmailRecipientsDTO }>(),
);

export const upsertRecipientEmailsSuccess = createAction(
  '[Alerting/API] Insert Email into Group Success',
);

export const createNewReceiverGroup = createAction(
  '[Alerting/API] Create new receiver Group',
  props<{ dto: RecipientGroup }>(),
);

export const createNewApproverGroup = createAction(
  '[Alerting/API] Create new approver Group',
  props<{ dto: RecipientGroup }>(),
);

export const createNewReceiverGroupSuccess = createAction(
  '[Alerting/API] Create new receiver Group Success',
  props<{ result: RecipientGroup[] }>(),
);

export const createNewApproverGroupSuccess = createAction(
  '[Alerting/API] Create new approver Group Success',
  props<{ result: RecipientGroup[] }>(),
);

export const setSelectedReceiverGroup = createAction(
  'Set selected receiver Group',
  props<{ id: string }>(),
);

export const setSelectedApproverGroup = createAction(
  'Set selected approver Group',
  props<{ id: string }>(),
);

export const deleteRecipientGroup = createAction(
  '[Alerting/API] Delete recipient Group',
  props<{ id: string; groupType: RecipientGroupType }>(),
);

export const deleteRecipientGroupSuccess = createAction(
  '[Alerting/API] Delete recipient Group Success',
  props<{ groupType: RecipientGroupType }>(),
);

export const fetchAlerts = createAction('[Alerting/API] Fetch Alerts');

export const fetchAlertsSuccess = createAction(
  '[Alerting/API] Fetch Alerts Success',
  props<{ result: Alert[] }>(),
);

export const fetchNotifications = createAction(
  '[Alerting/API] Fetch Notifications',
);

export const fetchNotificationsSuccess = createAction(
  '[Alerting/API] Fetch Notifications Success',
  props<{ result: PortalNotification[] }>(),
);

export const fetchAlertById = createAction(
  '[Alerting/API] Fetch Alert by Id',
  props<{ id: string }>(),
);

export const fetchAlertByIdSuccess = createAction(
  '[Alerting/API] Fetch Alert by Id Success',
  props<{ result: Alert }>(),
);

export const deleteAlertById = createAction(
  '[Alerting/API] Delete Alert by Id',
  props<{ id: string }>(),
);

export const deleteAlertByIdSuccess = createAction(
  '[Alerting/API] Delete Alert by Id Success',
  props<{ result: string }>(),
);

export const approveAlertById = createAction(
  '[Alerting/API] Approve Alert by Id',
  props<{ id: string }>(),
);

export const approveAlertByIdSuccess = createAction(
  '[Alerting/API] Approve Alert by Id Success',
  props<{ result: Alert }>(),
);

export const updateAlertDataById = createAction(
  '[Alerting/API] Update Alert Data by Id',
  props<{ id: string }>(),
);

export const updateAlertDataByIdSuccess = createAction(
  '[Alerting/API] Update Alert Data by Id Success',
  props<{ result: Alert }>(),
);

export const selectGroupToDelete = createAction(
  '[Alerting/API] Select Group To Delete',
  props<{ group: RecipientGroup }>(),
);
