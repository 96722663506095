<div
  [class]="classes"
  (click)="onSvgClicked($event)"
  [style.width]="size + 'px'"
  [style.height]="'auto'"
  [style.background-color]="bgColor"
  [style.transform]="'rotate(' + rotation + 'deg)'"
>
  <ng-container *ngIf="name === 'blank_icon'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      [attr.fill]="bgColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" fill="fill" />
      <rect x="13.5" y="13.5" width="74" height="74" stroke="white" />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'check'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      [attr.fill]="bgColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.8709 21L72.8064 26.0645C61.4919 37.4035 51.1618 48.5218 40.2964 59.6173L27.5233 49.3764L21.9374 44.9076L13 56.0795L18.5859 60.5482L36.4608 74.8481L41.4508 78.8327L45.994 74.2896C58.8521 61.404 70.3715 48.7477 82.9354 36.1565L88 31.0919L77.8709 21Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'chevron_bottom'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.4517 54.2201L78.8301 27L88 36.556L50.4517 72.4633L13 36.556L22.1699 27L50.4517 54.2201Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'chevron_left'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.7799 50.4517L73 78.8301L63.444 88L27.5367 50.4517L63.444 13L73 22.1699L45.7799 50.4517Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'chevron_right'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.2201 50.4517L27 78.8301L36.556 88L72.4633 50.4517L36.556 13L27 22.1699L54.2201 50.4517Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'chevron_top'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.5483 45.7799L21.1699 73L12 63.444L49.5483 27.5367L87 63.444L77.8301 73L49.5483 45.7799Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'close'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80 29.7633L70.2424 20.0038L50.0019 40.2445L29.7595 20L20 29.7598L40.2442 50.0041L20 70.2413L29.7595 80.0008L50.0019 59.7602L70.2424 79.9972L80 70.2413L59.7595 50.0041L80 29.7633Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'collapse'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3349 49.8388L54.75 71.1226L47.583 78L20.6525 49.8388L47.583 21.75L54.75 28.6274L34.3349 49.8388Z"
        [attr.fill]="fill"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.4951 49.8388L78.9102 71.1226L71.7432 78L44.8127 49.8388L71.7432 21.75L78.9102 28.6274L58.4951 49.8388Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'date'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_715_8207)">
        <path
          d="M78.8258 90.8469H22.8368C17.8671 90.8469 13.8208 86.8003 13.8208 81.8307V25.8418C13.8208 20.8722 17.8671 16.8254 22.8368 16.8254H78.8258C83.8029 16.8254 87.8418 20.8722 87.8418 25.8418V81.8307C87.8492 86.8077 83.8029 90.8469 78.8258 90.8469ZM22.8368 20.5102C19.8978 20.5102 17.504 22.9028 17.504 25.8418V81.8307C17.504 84.7697 19.8978 87.1621 22.8368 87.1621H78.8258C81.7648 87.1621 84.164 84.7697 84.164 81.8307V25.8418C84.164 22.9028 81.7721 20.5102 78.8258 20.5102H22.8368Z"
          [attr.fill]="fill"
        />
        <path
          d="M35.1736 26.404C34.1546 26.404 33.3347 25.577 33.3347 24.5653V12.0413C33.3347 11.0222 34.1619 10.2026 35.1736 10.2026C36.1852 10.2026 37.0197 11.0296 37.0197 12.0413V24.5653C37.0123 25.577 36.1852 26.404 35.1736 26.404Z"
          [attr.fill]="fill"
        />
        <path
          d="M66.2045 26.403C65.1854 26.403 64.3584 25.576 64.3584 24.5644V12.0403C64.3584 11.0212 65.1854 10.2016 66.2045 10.2016C67.2235 10.2016 68.0434 11.0286 68.0434 12.0403V24.5644C68.0507 25.576 67.2235 26.403 66.2045 26.403Z"
          [attr.fill]="fill"
        />
        <path
          d="M85.9975 37.6407H15.6525C14.6335 37.6407 13.8064 36.8137 13.8064 35.802C13.8064 34.7903 14.6335 33.9631 15.6525 33.9631H85.9975C87.0166 33.9631 87.8436 34.7903 87.8436 35.802C87.8436 36.8137 87.0166 37.6407 85.9975 37.6407Z"
          [attr.fill]="fill"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_715_8207"
          x="-4"
          y="0"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_715_8207"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_715_8207"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'filter'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5 50.5C35.5 55.4193 32.3427 59.6011 27.9439 61.1295L27.9748 61.3126L28 61.75L28 84.25C28 86.3211 26.3211 88 24.25 88C22.3269 88 20.7419 86.5524 20.5252 84.6874L20.5 84.25V61.75C20.5 61.5379 20.5176 61.3299 20.5514 61.1274C16.1573 59.601 13 55.4193 13 50.5C13 45.5807 16.1573 41.3989 20.5561 39.8705L20.5252 39.6874L20.5 39.25V16.75C20.5 14.6789 22.1789 13 24.25 13C26.1731 13 27.7581 14.4476 27.9748 16.3126L28 16.75V39.25C28 39.4621 27.9824 39.6701 27.9486 39.8726C32.3427 41.399 35.5 45.5807 35.5 50.5ZM28 50.5C28 48.4289 26.3211 46.75 24.25 46.75C22.1789 46.75 20.5 48.4289 20.5 50.5C20.5 52.5711 22.1789 54.25 24.25 54.25C26.3211 54.25 28 52.5711 28 50.5ZM61.75 24.25C61.75 29.1693 58.5927 33.3511 54.1939 34.8795L54.2248 35.0626L54.25 35.5L54.25 84.25C54.25 86.3211 52.5711 88 50.5 88C48.5769 88 46.9919 86.5524 46.7752 84.6874L46.75 84.25L46.75 35.5C46.75 35.2879 46.7676 35.0799 46.8014 34.8774C42.4073 33.351 39.25 29.1693 39.25 24.25C39.25 18.0368 44.2868 13 50.5 13C56.7132 13 61.75 18.0368 61.75 24.25ZM54.25 24.25C54.25 22.1789 52.5711 20.5 50.5 20.5C48.4289 20.5 46.75 22.1789 46.75 24.25C46.75 26.3211 48.4289 28 50.5 28C52.5711 28 54.25 26.3211 54.25 24.25ZM88 65.5C88 70.4193 84.8427 74.6011 80.4439 76.1295L80.4748 76.3126L80.5 76.75V84.25C80.5 86.3211 78.8211 88 76.75 88C74.8269 88 73.2419 86.5524 73.0252 84.6874L73 84.25V76.75C73 76.5379 73.0176 76.3299 73.0514 76.1274C68.6573 74.601 65.5 70.4193 65.5 65.5C65.5 60.5807 68.6573 56.3989 73.0561 54.8705L73.0252 54.6874L73 54.25V16.75C73 14.6789 74.6789 13 76.75 13C78.6731 13 80.2581 14.4476 80.4748 16.3126L80.5 16.75V54.25C80.5 54.4621 80.4824 54.6701 80.4486 54.8726C84.8427 56.399 88 60.5807 88 65.5ZM80.5 65.5C80.5 63.4289 78.8211 61.75 76.75 61.75C74.6789 61.75 73 63.4289 73 65.5C73 67.5711 74.6789 69.25 76.75 69.25C78.8211 69.25 80.5 67.5711 80.5 65.5Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'search'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.999 79.5177L68.027 59.5455C71.1695 54.7987 72.9983 49.1175 72.9983 43.0018C72.9983 26.4308 59.5676 13 42.9992 13C26.4305 13 13 26.4308 13 43.0018C13 59.5684 26.4306 73.0037 42.9992 73.0037C49.1124 73.0037 54.8003 71.1683 59.5426 68.0279L79.5144 88L87.999 79.5177ZM21.5007 43.0064C21.5007 31.1319 31.1272 21.5097 42.9992 21.5097C54.8735 21.5097 64.5 31.1319 64.5 43.0064C64.5 54.881 54.8734 64.5076 42.9992 64.5076C31.1272 64.5076 21.5007 54.881 21.5007 43.0064Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'open_link'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.5 53.3846C49.7615 53.3846 49.0231 53.1034 48.4606 52.5394C47.3341 51.413 47.3341 49.587 48.4606 48.4606L72.3827 24.5385H15.8846C14.2923 24.5385 13 25.8308 13 27.4231V85.1154C13 86.7077 14.2923 88 15.8846 88H73.5769C75.1692 88 76.4615 86.7077 76.4615 85.1154V28.6173L52.5394 52.5394C51.9755 53.1034 51.2385 53.3846 50.5 53.3846Z"
        [attr.fill]="fill"
      />
      <path
        d="M85.1154 13H67.8077C66.2154 13 64.9231 14.2923 64.9231 15.8846C64.9231 17.4769 66.2154 18.7692 67.8077 18.7692H78.1519L72.3827 24.5385H73.5769C75.1692 24.5385 76.4616 25.8308 76.4616 27.4231V28.6173L82.2308 22.8481V33.1923C82.2308 34.7846 83.5231 36.0769 85.1154 36.0769C86.7077 36.0769 88 34.7846 88 33.1923V15.8846C88 14.2923 86.7077 13 85.1154 13Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'settings'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.7 42.625L72.175 37.075L63.625 28.675L58.15 34.225C57.625 34 57.025 33.775 56.5 33.55V25.75H44.5V33.55C43.9 33.775 43.225 34 42.625 34.3L37.075 28.825L28.675 37.375L34.225 42.85C34 43.375 33.775 43.975 33.55 44.5H25.75V56.5H33.55C33.775 57.1 34 57.775 34.3 58.375L28.825 63.925L37.375 72.325L42.85 66.775C43.375 67 43.975 67.225 44.5 67.45V75.25H56.5V67.45C57.1 67.225 57.775 67 58.375 66.7L63.925 72.175L72.325 63.625L66.775 58.15C67 57.625 67.225 57.025 67.45 56.5H75.25V44.5H67.45C67.225 43.9 67 43.225 66.7 42.625ZM50.5 60.25C45.1 60.25 40.75 55.9 40.75 50.5C40.75 45.1 45.1 40.75 50.5 40.75C55.9 40.75 60.25 45.1 60.25 50.5C60.25 55.9 55.9 60.25 50.5 60.25Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'help'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.9181 59.2212H46.6249C46.6085 58.3163 46.6002 57.7652 46.6002 57.5677C46.6002 55.5276 46.9375 53.8494 47.6121 52.5332C48.2867 51.2169 49.6358 49.7362 51.6595 48.0909C53.6832 46.4456 54.8925 45.3679 55.2873 44.8579C55.8961 44.0517 56.2005 43.1633 56.2005 42.1925C56.2005 40.8434 55.6575 39.6917 54.5716 38.7374C53.5022 37.7667 52.0544 37.2814 50.2281 37.2814C48.4676 37.2814 46.9951 37.7832 45.8105 38.7868C44.6259 39.7904 43.8115 41.3205 43.3673 43.3771L37 42.5874C37.181 39.6423 38.4314 37.1415 40.7512 35.0849C43.0876 33.0283 46.1478 32 49.9319 32C53.9135 32 57.0807 33.0448 59.4335 35.1343C61.7862 37.2073 62.9626 39.6259 62.9626 42.39C62.9626 43.9201 62.5266 45.3679 61.6546 46.7335C60.799 48.0991 58.9563 49.9583 56.1264 52.311C54.6621 53.5285 53.749 54.5075 53.387 55.2479C53.0415 55.9883 52.8852 57.3127 52.9181 59.2212ZM46.6249 68.55V61.6151H53.5598V68.55H46.6249Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'note'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1083 61.7415L26.3417 15.0498C26.4427 14.6935 26.6129 14.3605 26.8427 14.07C27.0724 13.7795 27.3572 13.5371 27.6808 13.3568C28.0043 13.1765 28.3602 13.0617 28.7281 13.019C29.096 12.9764 29.4688 13.0066 29.825 13.1082L73.0927 25.3733L64.6327 33.8332H27.3335C26.9844 33.8336 26.6496 33.9724 26.4027 34.2192C26.1558 34.4661 26.0168 34.8008 26.0164 35.15V83.683C26.0168 84.0322 26.1557 84.367 26.4026 84.614C26.6495 84.8609 26.9843 84.9998 27.3335 85.0002H75.8666C76.2158 84.9997 76.5505 84.8608 76.7973 84.6139C77.0442 84.3669 77.183 84.0322 77.1834 83.683V46.6833L80.183 43.6837V83.683C80.1818 84.8275 79.7267 85.9247 78.9174 86.734C78.1082 87.5432 77.0111 87.9985 75.8666 87.9998H27.3335C26.189 87.9986 25.0918 87.5434 24.2825 86.7341C23.4732 85.9248 23.018 84.8275 23.0167 83.683V67.4831L15.05 65.2248C14.3308 65.02 13.7223 64.5381 13.3583 63.885C12.9942 63.2318 12.9043 62.4609 13.1083 61.7415ZM79.7822 24.5764L53.8533 50.5053C53.5601 50.7985 53.3493 51.1637 53.2421 51.5642L51.3342 58.6919C51.2919 58.8498 51.2919 59.016 51.3342 59.1739C51.3765 59.3317 51.4596 59.4757 51.5752 59.5913C51.6908 59.7068 51.8347 59.7899 51.9926 59.8323C52.1504 59.8746 52.3166 59.8746 52.4745 59.8323L59.6024 57.9242C60.0029 57.817 60.3681 57.6062 60.6613 57.313L86.59 31.3843C87.492 30.4813 87.9985 29.2572 87.9982 27.9809C87.9979 26.7046 87.4908 25.4807 86.5883 24.5782C85.6858 23.6757 84.4619 23.1686 83.1856 23.1682C81.9094 23.1679 80.6852 23.6744 79.7822 24.5764Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'image'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.3648 21H76.6352C82.8878 21 88 26.1199 88 32.3648V67.5995C88 73.8444 82.8878 78.9643 76.6352 78.9643H24.3648C18.1122 78.9643 13 73.8444 13 67.5995V32.3648C13 26.1199 18.1122 21 24.3648 21ZM66.5791 29.3418C69.7934 29.3418 72.3954 31.9439 72.3954 35.1582C72.3954 38.3724 69.7934 40.9821 66.5791 40.9821C63.3648 40.9821 60.7551 38.3724 60.7551 35.1582C60.7551 31.9439 63.3648 29.3418 66.5791 29.3418ZM29.0944 61.1633L42.449 39.4821C43.5663 38.1964 46.0995 38.1964 47.2168 39.4821L55.8878 53.5485L59.324 47.9694C60.1199 47.051 61.9184 47.051 62.7143 47.9694L72.1964 63.3673C73.9872 66.2755 72.0969 69.8418 68.9286 69.8418C57.1811 69.8418 45.4337 69.8418 33.6862 69.8418C29.1786 69.8418 26.5689 65.2576 29.0944 61.1633Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'chart'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3438 17V77.9375V82.625H29.4062H34.0938H48.1562H52.8438H66.9062H71.5938H80.9688H85.6562V54.5H71.5938V77.9375H66.9062V31.0625H52.8438V77.9375H48.1562V40.4375H34.0938V77.9375H29.4062V17H15.3438Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>
  <ng-container *ngIf="name === 'details'">
    <svg
      [attr.width]="size"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3594 13C20.6041 13 20 13.5945 20 14.3367V86.6758C20 87.418 20.6041 88 21.3594 88H78.6406C79.3959 88 80 87.412 80 86.6758V35.5H57.5V13H21.3594ZM62 13V31.0125H80L62 13ZM27.5 27.239H50V31.75H27.5V27.239ZM27.5 37H50V41.5125H27.5V37ZM27.5 46.75H72.5V51.2625H27.5V46.75ZM27.5 56.5H72.5V61.0125H27.5V56.5ZM27.5 66.25H72.5V70.7625H27.5V66.25ZM27.5 75.989H72.5V80.5H27.5V75.989Z"
        [attr.fill]="fill"
      />
    </svg>
  </ng-container>

  <ng-container *ngIf="name === 'home'">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      enable-background="new 0 0 64 64"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M53,59H36V45h-8v14H11V36H0.2L32,4.2L63.8,36H53V59z M40,55h9V32h5.2L32,9.8L9.8,32H15v23h9V41h16V55z"
            [attr.fill]="fill"
          />
        </g>
      </g>
    </svg>
  </ng-container>
</div>
