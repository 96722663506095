import { featureConfig, User } from '@interfaces';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserState {
  user: User | null;
  featureFlags: featureConfig | null;
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialUserState: UserState = userAdapter.getInitialState({
  user: null,
  featureFlags: null,
});

const reducer = createReducer(
  initialUserState,
  on(UserActions.setUser, (state, { user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(UserActions.setUserFeatureFlags, (state, { featureFlags }) => {
    return {
      ...state,
      featureFlags,
    };
  }),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
