import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { selectReceiversGroups } from '@core-app/state/alerting';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { createNewDailyProductionTargetMissedFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-daily-production-target-missed-form',
  templateUrl: './daily-production-target-missed-form.component.html',
  styleUrl: './daily-production-target-missed-form.component.scss',
})
export class DailyProductionTargetMissedFormComponent implements OnInit {
  @Input() submitButtonText = 'Submit';
  @Input() formGroup = createNewDailyProductionTargetMissedFormGroup();
  @Output() submitForm = new EventEmitter();

  private readonly _store = inject(Store);

  recipientGroups$ = this._store.select(selectReceiversGroups());

  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._store.dispatch(AlertingActions.fetchReceiversGroup());
  }

  triggerValidation() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
  }

  submit() {
    this.triggerValidation();

    if (this.formGroup.valid) {
      this.submitForm.emit();
    }
  }
}
