import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Article } from '@interfaces';
import { setArticle } from './articles.actions';

export const ARTICLES_FEATURE_KEY = 'articles';

export interface ArticlesState {
  article: Article | null;
}

export interface ArticlesPartialState {
  readonly [ARTICLES_FEATURE_KEY]: ArticlesState;
}

export const articlesAdapter: EntityAdapter<Article> =
  createEntityAdapter<Article>();

export const initialArticlesState: ArticlesState = {
  article: null,
};

const reducer = createReducer(
  initialArticlesState,
  on(setArticle, (state, { article }) => {
    return {
      ...state,
      article,
    };
  }),
);

export function articlesReducer(
  state: ArticlesState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
