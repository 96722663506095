<!-- <div class="table-container"> -->
<div [class]="containerClass">
  <div class="table-wrapper">
    <table
      class="fip-table"
      mat-table
      [dataSource]="dataSource"
      [ngClass]="isCompact ? 'compact' : ''"
    >
      <ng-container
        [matColumnDef]="column.index"
        *ngFor="let column of columns"
      >
        <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>

        <ng-container *ngIf="column.key === 'actions'; else default">
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [ngClass]="{ highlight: highlightedRowIndex === element.index }"
          >
            <div class="action-links">
              <button
                mat-icon-button
                *ngFor="let link of element.actions"
                (click)="link.action(element)"
                [title]="link.label"
                class="small-icon-button"
              >
                <mat-icon>{{ link.icon }}</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-template #default>
          <td
            mat-cell
            data-cy="table-cell"
            *matCellDef="let element; let i = index"
            class="default"
            [ngClass]="{ highlight: highlightedRowIndex === element.index }"
          >
            {{ element[column.key] }}
          </td>
        </ng-template>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="handleRowClick(row)"
      ></tr>
    </table>
  </div>

  <mat-paginator
    *ngIf="paginated"
    showFirstLastButtons
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
  >
  </mat-paginator>

  <div *ngIf="exportable" class="flex w-full justify-end">
    <button
      (click)="handleExportClick()"
      class="mr-4 cursor-pointer text-xs text-gray-400 underline"
    >
      Export Data
    </button>
  </div>
</div>
