import { Component, DestroyRef, inject } from '@angular/core';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { createNewDailyProductionTargetMissedFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { Monitor } from '@interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-daily-production-target-missed-modal',
  templateUrl: './daily-production-target-missed-modal.component.html',
  styleUrl: './daily-production-target-missed-modal.component.scss',
})
export class DailyProductionTargetMissedModalComponent {
  destroyRef = inject(DestroyRef);

  formGroup = createNewDailyProductionTargetMissedFormGroup();

  private readonly _store = inject(Store);

  submit() {
    const { name, recipientGroupId, productionId, productionTarget } =
      this.formGroup.value;

    const monitor: Monitor = {
      partitionKey: 'v1',
      monitorType: 'daily_production_target_missed_by_x_percent',
      name,
      recipientGroupId,
      productionId,
      productionTarget,
      priorityLevel: 'P2',
    };

    this._store.dispatch(AlertingActions.upsertMonitor({ monitor }));
  }
}
