import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { LoggedInLayoutComponent } from './core/logged-in-layout/logged-in-layout.component';
import { LoggedOutLayoutComponent } from './core/logged-out-layout/logged-out-layout.component';
import { AuthComponent } from './core/auth/auth.component';
import { FeatureGuard, LoginGuard } from './guards';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: environment.isTestEnv ? undefined : [LoginGuard],
    component: LoggedOutLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AuthComponent,
      },
    ],
  },
  {
    path: '',
    component: LoggedInLayoutComponent,
    canActivate: environment.isTestEnv ? undefined : [MsalGuard],
    children: [
      {
        path: 'dashboard',
        pathMatch: 'full',
        canActivate: environment.isTestEnv ? undefined : [MsalGuard],
        loadChildren: () =>
          import('./dashboard/dashboard-page.module').then(
            (module) => module.DashboardPageModule,
          ),
      },
      {
        path: 'parts',
        canActivate: environment.isTestEnv ? undefined : [MsalGuard],
        loadChildren: () =>
          import('./parts-page/parts-page.module').then(
            (module) => module.PartsPageModule,
          ),
      },
      {
        path: 'batch',
        canActivate: environment.isTestEnv ? undefined : [MsalGuard],
        loadChildren: () =>
          import('./batch-page/batch-page.module').then(
            (module) => module.BatchPageModule,
          ),
      },
      {
        path: 'spc',
        canActivate: environment.isTestEnv ? undefined : [MsalGuard],
        loadChildren: () =>
          import('./spc/spc.module').then((module) => module.SPCPageModule),
        canLoad: [FeatureGuard],
        data: { feature: 'SPC' },
      },
      {
        path: 'production',
        canActivate: environment.isTestEnv ? undefined : [MsalGuard],
        loadChildren: () =>
          import('./production/production.module').then(
            (module) => module.ProductionPageModule,
          ),
        canLoad: [FeatureGuard],
        data: { feature: 'production' },
      },
      {
        path: 'alerting',
        canActivate: environment.isTestEnv ? undefined : [MsalGuard],
        loadChildren: () =>
          import('./alerting/alerting.module').then(
            (module) => module.AlertingModule,
          ),
        canLoad: [FeatureGuard],
        data: { feature: 'alerting' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
