import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { selectReceiversGroups } from '@core-app/state/alerting';
import { Store } from '@ngrx/store';

@Component({
  selector:
    'frontend-workspace-daily-production-target-missed-general-config-modal',
  templateUrl:
    './daily-production-target-missed-general-config-modal.component.html',
  styleUrl:
    './daily-production-target-missed-general-config-modal.component.scss',
})
export class DailyProductionTargetMissedGeneralConfigModalComponent
  implements OnInit
{
  formGroup = new FormGroup({
    approversGroupId: new FormControl('', [Validators.required]),
  });
  private readonly _store = inject(Store);

  recipientGroups$ = this._store.select(selectReceiversGroups());

  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._store.dispatch(AlertingActions.fetchReceiversGroup());
  }

  submit() {
    if (this.formGroup.valid) {
      return;
    }
  }
}
