import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface TableColumn<T> {
  index: string;
  label: string;
  key: keyof T;
}
type IndexedRow<T> = {
  index: number;
  [key: string]: T[keyof T] | number;
};

@Component({
  selector: 'fip-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, OnChanges {
  @Input({ required: true }) data: T[] = [];
  @Input({ required: true }) displayedColumns: string[] = [];
  @Input({ required: true }) columns: Array<TableColumn<T>> = [];
  @Input() paginated = false;
  @Input() contained = false;
  @Input() pageSizeOptions: number[] = [5, 10];
  @Input() exportable = false;
  @Input() isCompact = false;
  @Output() rowClicked = new EventEmitter<T>();
  dataSource = new MatTableDataSource<T>();
  pageIndex = 0;
  highlightedRowIndex = -1;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  private readonly _cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.data = this.data.map((row, index) => {
      // Clone the object to avoid changing the original object and add index property
      return { ...row, index } as IndexedRow<T>;
    }) as T[];

    if (this.paginated) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.data;
    } else {
      this.dataSource.data = this.data;
    }

    this._cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'].currentValue) {
      this._cdr.detectChanges();
    }
  }

  handleRowClick(e: T) {
    this.rowClicked.emit(e);
  }

  handleExportClick() {
    downloadStringAsBlob(arrayToCsv(this.dataRows));
  }

  get dataRows() {
    const columns = this.columns.filter((col) => col.index !== 'actions');
    return [
      // table header
      columns.map((col) => {
        return col.label;
      }),
      // rows
      ...this.data.map((row) => {
        return columns.map((col) => {
          return row[col.key];
        });
      }),
    ];
  }

  get containerClass() {
    return `table-container ${this.contained ? 'contained' : ''}`;
  }

  goToPage(page: number) {
    if (!this.paginator) {
      return;
    }

    // Didn't work without setTimeout ¯\_(ツ)_/¯
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = page;
        this.pageIndex = page;
        this.paginator.page.next({
          pageIndex: page,
          pageSize: this.paginator.pageSize,
          length: this.data.length,
        });
      }
    }, 0);
  }

  goToItemIndex(index: number) {
    if (!this.paginator) {
      return;
    }
    this.highlightedRowIndex = index;

    const page = Math.floor(index / this.paginator.pageSize);
    this.goToPage(page);
  }
}

const arrayToCsv = (arr: unknown[][]) => {
  return arr
    .map((row) =>
      row
        .map((el) => {
          if (typeof el === 'string') {
            return `"${el.replaceAll('\n', ';')}"`;
          }
          return `"${el}"`;
        })
        .join(','),
    )
    .join('\n');
};

const downloadStringAsBlob = (data: string, filename = 'export') => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}-${new Date().getTime()}.csv`);
  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 400);
};
