<div>
  <div class="absolute -top-7 right-0 mr-2 mt-9 flex">
    <mat-button-toggle-group
      name="view"
      aria-label="View"
      color="primary"
      [(ngModel)]="currentDataToShow"
      [hideSingleSelectionIndicator]="true"
    >
      <mat-button-toggle
        [value]="ToggleDataToShow.Chart"
        [disabled]="disableChartBtn"
      >
        <mat-icon color="primary">bar_chart</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle [value]="ToggleDataToShow.Table">
        <mat-icon color="primary">description</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <ng-content
    *ngIf="currentDataToShow === ToggleDataToShow.Chart"
    select="[chart]"
  ></ng-content>
  <ng-content
    *ngIf="currentDataToShow === ToggleDataToShow.Table"
    select="[table]"
  ></ng-content>
</div>
