/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, inject } from '@angular/core';
import {
  ApiResponse,
  Article,
  BomNode,
  Company,
  Equipment,
  IncomingOrPeriodicInspection,
  InlineInspection,
  LineChartDataPoints,
  ProcessParameter,
  ProductionNumber,
  SPCGeneralData,
} from '@interfaces';
import { ChartType, InspectionType, ProcessType } from '@enums';
import {
  processIncomingInspectionData,
  processInlineInspectionData,
} from '@helpers';
import { Observable, map, of } from 'rxjs';
import { ApiService } from './api.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private readonly _apiPath = 'articles';
  private readonly _api = inject(ApiService);

  getArticle(
    articleId: string,
    batchIds?: string[] | null,
    rootArticleId?: string | null,
    rootBatchId?: string | null,
  ): Observable<ApiResponse<Article>> {
    return this._api.get<Article>(
      `${this._apiPath}/${articleId}/?batchId=${batchIds?.join(
        '&batchId=',
      )}&rootArticleId=${rootArticleId}&rootBatchId=${rootBatchId}`,
    );
  }

  getBomTree(
    articleId: string | undefined,
    batchId: string | undefined,
  ): Observable<ApiResponse<BomNode>> {
    if (!articleId || !batchId) {
      return of({ data: null });
    }
    return this._api.get<BomNode>(
      `${this._apiPath}/${articleId}/batches/${batchId}/bom`,
    );
  }

  getIncomingInspections(
    inspections: InspectionType,
    articleId: BomNode['articleId'],
    batchIds: BomNode['batchId'][],
    rootArticleId: BomNode['articleId'],
    rootBatchId: BomNode['batchId'],
  ): Observable<ApiResponse<IncomingOrPeriodicInspection[]>> {
    return this._api
      .get<IncomingOrPeriodicInspection[]>(
        `${this._apiPath}/${articleId}/${this.mapInspectionTypeToApiPath(
          inspections,
          true,
        )}?batchId=${batchIds?.join(
          '&batchId=',
        )}&rootArticleId=${rootArticleId}&rootBatchId=${rootBatchId}`,
      )
      .pipe(
        map((result) => {
          if (!result.data) {
            return { data: null };
          }

          result.data = result.data.map((inspection) =>
            processIncomingInspectionData(
              inspection,
              articleId,
              batchIds,
              rootArticleId,
              rootBatchId,
            ),
          );

          return result;
        }),
      );
  }

  getInlineInspections(
    inspections: InspectionType,
    articleId: BomNode['articleId'],
    batchIds: BomNode['batchId'][],
    rootArticleId: BomNode['articleId'],
    rootBatchId: BomNode['batchId'],
    processType?: ProcessType,
  ): Observable<ApiResponse<InlineInspection[]>> {
    const params = {
      batchId: batchIds,
      rootArticleId,
      rootBatchId,
    };

    if (processType) {
      Object.assign(params, { processType });
    }

    return this._api
      .get<InlineInspection[]>(
        `${this._apiPath}/${articleId}/${this.mapInspectionTypeToApiPath(
          inspections,
          true,
        )}`,
        {
          params,
        },
      )
      .pipe(
        map((result) => {
          if (!result.data) {
            return { data: null };
          }

          result.data = result.data.map((inspection) =>
            processInlineInspectionData(
              inspection,
              articleId,
              batchIds,
              rootArticleId,
              rootBatchId,
            ),
          );

          return result;
        }),
      );
  }

  getPeriodicInspections(
    inspections: InspectionType,
    articleId: BomNode['articleId'],
    batchIds: BomNode['batchId'][],
    rootArticleId: BomNode['articleId'],
    rootBatchId: BomNode['batchId'],
  ): Observable<ApiResponse<IncomingOrPeriodicInspection[]>> {
    return this._api
      .get<IncomingOrPeriodicInspection[]>(
        `${this._apiPath}/${articleId}/${this.mapInspectionTypeToApiPath(
          inspections,
          true,
        )}?batchId=${batchIds?.join(
          '&batchId=',
        )}&rootArticleId=${rootArticleId}&rootBatchId=${rootBatchId}`,
      )
      .pipe(
        map((result) => {
          if (!result.data) {
            return { data: null };
          }

          result.data = result.data.map((inspection) =>
            processIncomingInspectionData(
              inspection,
              articleId,
              batchIds,
              rootArticleId,
              rootBatchId,
            ),
          );

          return result;
        }),
      );
  }

  getMeasurementsModalData(
    productionOrderId: InlineInspection['productionOrderId'],
    nodeId: ProcessParameter['nodeId'],
    articleId: InlineInspection['articleId'],
    batchId: InlineInspection['batchId'],
    rootBatchId: InlineInspection['rootBatchId'],
    rootArticleId: InlineInspection['rootArticleId'],
    processType: ProcessType,
  ) {
    return this._api.post(
      `production-orders/${productionOrderId}/${processType.toLowerCase()}`,
      {
        nodeId,
        articleId,
        batchId,
        rootBatchId,
        rootArticleId,
      },
    );
  }

  getCompanies(articleId: string) {
    return this._api.get<Company[]>(`${this._apiPath}/${articleId}/companies`);
  }

  getPlannedBom(articleId: string, companyCode: string) {
    return this._api
      .get<ProductionNumber[]>(`${this._apiPath}/${articleId}/planned-bom`, {
        params: { companyCode },
      })
      .pipe(
        map((result) => {
          result.data?.forEach((productionNumber) => {
            productionNumber.derivedPeakPercentageDemand =
              productionNumber.peakPercentageDemand * 100 + '%';

            productionNumber.stockAvailable =
              productionNumber.stockAvailable ?? '-';

            productionNumber.cycleStock = productionNumber.cycleStock ?? '-';
            productionNumber.outputPerDay =
              productionNumber.outputPerDay ?? '-';
            productionNumber.dosagePerDay =
              productionNumber.dosagePerDay ?? '-';
          });

          return result;
        }),
      );
  }

  mapInspectionTypeToApiPath(inspectionType: InspectionType, plural = false) {
    switch (inspectionType) {
      case InspectionType.Incoming:
        return 'incoming-inspection' + (plural ? 's' : '');
      case InspectionType.Inline:
        return 'inline-inspection' + (plural ? 's' : '');
      case InspectionType.Periodic:
        return 'periodic-inspection' + (plural ? 's' : '');
    }
  }

  getSPCGeneralData({
    articleId,
    rootArticleId,
    rootBatchId,
    batchId,
    parameter,
    processType,
    inspectionType,
  }: {
    articleId: string;
    batchId: string;
    rootArticleId: string;
    rootBatchId: string;
    parameter: string;
    processType: string;
    inspectionType: InspectionType;
  }) {
    return this._api.post<
      SPCGeneralData,
      {
        rootArticleId: string;
        rootBatchId: string;
        batchId: string;
        parameter: string;
        processType: string;
      }
    >(
      `${this._apiPath}/${articleId}/spc/${this.mapInspectionTypeToApiPath(
        inspectionType,
      )}`,
      {
        rootArticleId,
        rootBatchId,
        batchId,
        parameter,
        processType,
      },
    );
  }

  getSPCChartsData({
    articleId,
    rootArticleId,
    rootBatchId,
    batchId,
    parameter,
    processType,
    subgroupSize,
    samplingInterval,
    numberOfGroups,
    productionOrderId,
    inspectionType,
    chartType,
    equipmentIds,
    isIntervalSampling,
    start,
    end,
  }: {
    articleId: string;
    batchId: string;
    rootArticleId: string;
    rootBatchId: string;
    parameter: string | null;
    processType: string;
    subgroupSize: number;
    samplingInterval?: string | null;
    numberOfGroups: number;
    productionOrderId?: string;
    inspectionType: InspectionType;
    chartType: ChartType;
    equipmentIds?: string[] | null;
    isIntervalSampling?: boolean;
    start: string;
    end: string;
  }) {
    if (equipmentIds?.length === 1 && equipmentIds[0] === '') {
      equipmentIds = null;
    }

    const params = {
      rootArticleId,
      rootBatchId,
      batchId,
      parameter,
      processType,
      subgroupSize,
      samplingInterval,
      productionOrderId,
      numberOfGroups,
      chartType,
      equipmentIds,
      isIntervalSampling,
      start,
      end,
    };

    if (!samplingInterval) {
      delete params.samplingInterval;
    }

    if (productionOrderId) {
      Object.assign(params, { productionOrderId });
    }

    return this._api.post(
      `${this._apiPath}/${articleId}/spc/${this.mapInspectionTypeToApiPath(
        inspectionType,
      )}/spc-charts`,
      params,
    );
  }

  getCharacteristics(
    articleId: string,
    batchId: string,
    rootArticleId: string,
    rootBatchId: string,
    inspectionType: InspectionType,
  ) {
    return this._api.get<{
      batchId: string;
      processType: string;
      characteristics: { id: string; name: string }[];
    }>(
      `${this._apiPath}/${articleId}/should-value/${batchId}/characteristics`,
      {
        params: {
          articleId,
          batchId,
          rootArticleId,
          rootBatchId,
          inspectionType,
        },
      },
    );
  }

  getEquipments(
    articleId: string,
    batchId: string,
    rootArticleId: string,
    rootBatchId: string,
    nodeId: string,
  ) {
    return this._api.post<
      Equipment[],
      { rootArticleId: string; rootBatchId: string; nodeId: string }
    >(`${this._apiPath}/${articleId}/should-value/${batchId}/equipments`, {
      rootArticleId,
      rootBatchId,
      nodeId,
    });
  }

  getOEEMetrics({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    return of({
      data: {
        metrics: {
          target: 0.81,
          availability: 0.763,
          performance: 0.8972,
          quality: 0.9622,
        },
        tableData: [
          {
            title: 'Issue 1',
            rootCause: 'Issue 1 rootCause',
            timestamp: '2021-08-01',
            lineNum: '1',
            stationNum: '1',
            downTime: 1,
            downTimeCategory: 'Category 1',
            eightDReportId: '123',
            correctiveAction: 'Corrective action 1',
          },
          {
            title: 'Issue 2',
            rootCause: 'Issue 2 description',
            timestamp: '2021-08-02',
            lineNum: '2',
            stationNum: '2',
            downTime: 2,
            downTimeCategory: 'Category 2',
            eightDReportId: '124',
            correctiveAction: 'Corrective action 2',
          },
        ],
      },
    });

    // return this._api.get<{
    //   target: number;
    //   availability: number;
    //   performance: number;
    //   quality: number;
    // }>(`${this._apiPath}/${articleId}/oee-metrics/${batchId}`);
  }

  getAvailabilityData({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    const chartData: Array<LineChartDataPoints> = [{ x: [], y: [] }];

    const tableData = [
      {
        title: 'Issue 1',
        rootCause: 'Issue 1 rootCause',
        timestamp: '2021-08-01',
        lineNum: '1',
        stationNum: '1',
        downTime: 1,
        downTimeCategory: 'Category 1',
        eightDReportId: '123',
        correctiveAction: 'Corrective action 1',
      },
      {
        title: 'Issue 2',
        rootCause: 'Issue 2 description',
        timestamp: '2021-08-02',
        lineNum: '2',
        stationNum: '2',
        downTime: 2,
        downTimeCategory: 'Category 2',
        eightDReportId: '124',
        correctiveAction: 'Corrective action 2',
      },
    ];

    for (let i = 0; i < 30; i++) {
      chartData[0].x.push(i);
      chartData[0].y.push(Math.floor(Math.random() * 100));
    }

    return of({
      data: { chartData, tableData, target: 81, actual: 52 },
    });

    // return this._api.get<{
    //   availability: number;
    //   performance: number;
    //   quality: number;
    // }>(`${this._apiPath}/${articleId}/availability-data/${batchId}`, {
    //   params: {
    //     from: from?.toISOString(),
    //     to: to?.toISOString(),
    //   },
    // });
  }

  getPerformanceData({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    const chartData: [{ x: any[]; y: any[] }] = [
      {
        x: [1800, 1620, 848],
        y: [
          'Robot<br>Programming',
          'Upgrading<br> to AGV',
          'Material<br>Handling Time',
        ],
      },
    ];

    const tableData = [
      {
        title: 'Issue 1',
        rootCause: 'Issue 1 rootCause',
        timestamp: '2021-08-01',
        lineNum: '1',
        stationNum: '1',
        downTime: 1,
        downTimeCategory: 'Category 1',
        eightDReportId: '123',
        correctiveAction: 'Corrective action 1',
      },
      {
        title: 'Issue 2',
        rootCause: 'Issue 2 description',
        timestamp: '2021-08-02',
        lineNum: '2',
        stationNum: '2',
        downTime: 2,
        downTimeCategory: 'Category 2',
        eightDReportId: '124',
        correctiveAction: 'Corrective action 2',
      },
    ];

    return of({
      data: { chartData, tableData },
    });

    // return this._api.get<{
    //   availability: number;
    //   performance: number;
    //   quality: number;
    // }>(`${this._apiPath}/${articleId}/performance-data/${batchId}`, {
    //   params: {
    //     from: from?.toISOString(),
    //     to: to?.toISOString(),
    //   },
    // });
  }

  getQualityData({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    const issueData: any[] = [];

    const tableData = [
      {
        title: 'Issue 1',
        rootCause: 'Issue 1 rootCause',
        timestamp: '2021-08-01',
        lineNum: '1',
        stationNum: '1',
        downTime: 1,
        downTimeCategory: 'Category 1',
        eightDReportId: '123',
        correctiveAction: 'Corrective action 1',
      },
      {
        title: 'Issue 2',
        rootCause: 'Issue 2 description',
        timestamp: '2021-08-02',
        lineNum: '2',
        stationNum: '2',
        downTime: 2,
        downTimeCategory: 'Category 2',
        eightDReportId: '124',
        correctiveAction: 'Corrective action 2',
      },
    ];

    return of({
      data: { issueData, tableData },
    });

    // return this._api.get<{
    //   availability: number;
    //   performance: number;
    //   quality: number;
    // }>(`${this._apiPath}/${articleId}/quality-data/${batchId}`, {
    //   params: {
    //     from: from?.toISOString(),
    //     to: to?.toISOString(),
    //   },
    // });
  }
}
