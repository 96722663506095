import { createAction, props } from '@ngrx/store';
import { ModalKey } from '../types/modal-key';
import { SelectedPartData } from './app.reducer';
import { PartsSummaryDTO } from '@interfaces';

export const showModal = createAction(
  '[App/API] Show modal',
  props<{
    modalKey: ModalKey;
    data?: { [key: string]: unknown | undefined };
    width?: 'smallest' | 'medium' | 'large' | string;
  }>(),
);

export const selectPart = createAction(
  '[App/API] Set selected part',
  props<{ selectedPart: SelectedPartData }>(),
);

export const fetchSingleItemsByProductionOrder = createAction(
  '[Parts/API] Fetch Single Items By Production Order',
  props<{ productionOrderId: string }>(),
);

export const toggleSingleItemsByProductionOrderLoading = createAction(
  '[Parts/API] Toggle Single Items By Production Order Loading',
  props<{ loading: boolean }>(),
);

export const fetchSingleItemsByProductionOrderSuccess = createAction(
  '[Parts/API] Fetch Single Items By Production Order Success',
  props<{ items: PartsSummaryDTO[] | null; productionOrderId: string }>(),
);
