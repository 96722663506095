<div class="batch-bom">
  <ng-container *ngIf="bomLoading$ | async; else dataLoaded">
    <h3>
      <span class="skeleton-box w-4/5"></span>
      <span class="skeleton-box w-11/12"></span>
    </h3>
    <hr class="border-b-1 my-2 h-0.5 bg-gray-300 opacity-100 dark:opacity-50" />
    <h4 class="pt-2">
      <span class="skeleton-box w-3/5"></span>
    </h4>
    <div class="pt-4">
      <span class="skeleton-box w-1/2"></span><br />
      <span class="skeleton-box w-1/2"></span>
    </div>
    <div class="pt-4">
      <span class="skeleton-box w-1/2 text-5xl"></span>
    </div>
    <div class="pt-4">
      <span class="skeleton-box w-1/2"></span><br />
      <span class="skeleton-box w-1/2"></span>
    </div>
  </ng-container>

  <ng-template #dataLoaded>
    <ng-container *ngIf="dataState === ComponentDataState.HasData">
      <h3 class="text-base font-semibold leading-6 text-gray-900">
        {{ bom.description }}
      </h3>
      <hr
        class="border-b-1 my-2 h-0.5 bg-gray-300 opacity-100 dark:opacity-50"
      />
      <div class="pt-2">
        <h4 class="text-base font-semibold text-gray-900">
          External Article ID:
        </h4>
        <span class="text-base font-semibold text-gray-500">{{
          bom.externalArticleId
        }}</span>
      </div>
      <div class="pt-4">
        <h4 class="text-base font-semibold text-gray-900">FIP Article ID:</h4>
        <span class="text-base font-semibold text-gray-500">{{
          bom.articleId
        }}</span>
      </div>
      <ng-container *ngIf="bom.children.length">
        <div class="pt-4">
          <fip-form>
            <mat-form-field>
              <mat-label>Batches</mat-label>
              <mat-select
                #batches
                [formControl]="batchesControl"
                multiple
                (valueChange)="selectBatch.emit(batches.value)"
              >
                <mat-option
                  *ngFor="let BatchId of selectedBatchIds"
                  [value]="BatchId"
                >
                  {{ BatchId }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </fip-form>
        </div>
        <div class="pt-10">
          <fip-form formType="slide">
            <mat-slide-toggle [formControl]="showBomControl">
              Show BOM
            </mat-slide-toggle>
          </fip-form>
        </div>
        <div class="-ml-3 -mr-2 pt-5" *ngIf="showBomControl.value">
          <mat-tree
            [dataSource]="nestedDataSource"
            [treeControl]="nestedTreeControl"
            *ngIf="nestedDataSource.data.length"
            class="border-light-grey border-t"
          >
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <mat-button
                matTreeNodeToggle
                labelTextClass="text-md text-gray-800"
                class="w-full cursor-pointer select-none"
                [ngClass]="
                  'flex items-center px-2 py-3 ' +
                  (selectedArticleId === node?.articleId
                    ? 'bg-accent-yellow'
                    : 'bg-gray-100')
                "
                (click)="selectArticleId(node.articleId)"
                >{{ node?.articleId }}</mat-button
              >
            </mat-tree-node>
            <mat-nested-tree-node
              *matTreeNodeDef="let node; when: hasNestedChild"
              matTreeNodePadding
              class="mb-2"
            >
              <div class="mat-tree-node">
                <mat-button
                  mat-flat-button
                  class="w-full cursor-pointer select-none"
                  matTreeNodeToggle
                  [ngClass]="
                    'flex items-center px-2 py-3 ' +
                    (selectedArticleId === node?.articleId
                      ? 'bg-accent-yellow'
                      : 'bg-gray-100')
                  "
                  (click)="selectArticleId(node.articleId)"
                >
                  <mat-icon>{{
                    nestedTreeControl.isExpanded(node)
                      ? 'expand_more'
                      : 'chevron_right'
                  }}</mat-icon>
                  {{ node?.articleId }}</mat-button
                >
              </div>
              <div
                class="nested-node"
                [class.hidden]="!nestedTreeControl.isExpanded(node)"
              >
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="dataState === ComponentDataState.NoData">
      No BOM data available.
    </ng-container>
  </ng-template>
</div>
