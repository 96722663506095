import { LineChartData, Measurement } from '@interfaces';

export const groupBySampleId = (array: Measurement[]): Measurement[][] => {
  return array.reduce((r, a) => {
    r[a.sampleId] = r[a.sampleId] || [];
    r[a.sampleId].push(a);
    return r;
  }, Object.create([]));
};

export const factorial = (num: number): number => {
  if (num === 0) {
    return 1;
  }
  return num * factorial(num - 1);
};

export const calculateC4 = (measurements: Measurement[]): number => {
  const measurementsGroupedBySampleId = groupBySampleId(measurements);
  const numberOfSamples = Object.keys(measurementsGroupedBySampleId).length;

  return (
    Math.sqrt(2 / (numberOfSamples - 1)) *
    (factorial(Math.round(numberOfSamples / 2) - 1) /
      factorial(Math.round((numberOfSamples - 1) / 2) - 1))
  );
};

export const getMeanChartData = (
  measurements: Measurement[],
): LineChartData[] => {
  const measurementsGroupedBySampleId = groupBySampleId(measurements);
  const xBar = [];
  for (const measGroupedBy in measurementsGroupedBySampleId) {
    const arr: Measurement[] = measurementsGroupedBySampleId[measGroupedBy];
    const sum = arr.reduce((a, b) => a + b.measuredValue, 0);
    xBar.push({
      sampleId: arr[0].sampleId,
      value: sum / measurementsGroupedBySampleId[measGroupedBy].length,
    });
  }
  return xBar;
};

export const getStandardDeviationChartData = (
  measurements: Measurement[],
): LineChartData[] => {
  const xBarValues = getMeanChartData(measurements);
  const measurementsGroupedBySampleId = groupBySampleId(measurements);
  const sChartValues = [];
  for (const measGroupedBy in measurementsGroupedBySampleId) {
    const xBar = xBarValues[parseInt(measGroupedBy) - 1].value;
    const arr: Measurement[] = measurementsGroupedBySampleId[measGroupedBy];
    const sumSquare = arr.reduce(
      (a, b) => a + Math.pow(+xBar - b.measuredValue, 2),
      0,
    );
    sChartValues.push({
      sampleId: arr[0].sampleId,
      value: Math.sqrt(
        sumSquare / measurementsGroupedBySampleId[measGroupedBy].length,
      ),
    });
  }
  return sChartValues;
};

export const getUclAndLclForSChart = (
  measurements: Measurement[],
): { lcl: number; ucl: number } => {
  const c4Val = calculateC4(measurements);
  const sValues = getStandardDeviationChartData(measurements);
  const sBar = sValues.reduce((a, b) => a + b.value, 0) / sValues.length;
  const limit =
    ((3 * sBar) / c4Val) * Math.sqrt(Math.abs(1 - Math.pow(c4Val, 2)));
  return {
    lcl: Math.max(parseFloat((sBar - limit).toFixed(2)), 0),
    ucl: Math.max(parseFloat((sBar + limit).toFixed(2)), 0),
  };
};
