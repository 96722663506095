import { Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  RedirectRequest,
} from '@azure/msal-browser';
import { getUserFeatureFlags, setUser } from '@core-app/state/user';
import { APP_CONFIG } from '@frontend-workspace/app-config';
import { IdTokenClaims, User } from '@interfaces';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly _store: Store,
    private readonly _msalService: MsalService,
    private readonly _msalBroadcastService: MsalBroadcastService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(APP_CONFIG) private readonly _appConfig: any,
    @Inject(MSAL_GUARD_CONFIG)
    private readonly _msalGuardConfig: MsalGuardConfiguration,
  ) {}

  /** check whether the user is already authenticated, if so, save their data in ngrx store */
  saveAuthenticatedUser() {
    this._msalBroadcastService.msalSubject$
      .pipe(
        takeUntilDestroyed(),
        // only subscribe when the event type is a successful login
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this._setUserStore(payload);
      });

    const accounts = this._msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      this._msalService.instance.setActiveAccount(account);
      this._setUserStore(account);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._msalService.instance.addEventCallback((event: any) => {
      // set active account after redirect
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        this._msalService.instance.setActiveAccount(account);
      }
    });
  }

  /** Redirect user to azure authentication page */
  login() {
    if (this._msalGuardConfig.authRequest) {
      this._msalService.loginRedirect({
        ...this._msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this._msalService.loginRedirect();
    }
  }

  logout() {
    this._msalService.logoutRedirect({
      postLogoutRedirectUri: this._appConfig.aadRedirectUri,
    });
    this._store.dispatch(setUser({ user: null }));
  }

  /** Set authenticated user to the store */
  private _setUserStore(payload: AuthenticationResult | AccountInfo): void {
    const user: User = {
      firstName: (payload.idTokenClaims as IdTokenClaims).given_name,
      lastName: (payload.idTokenClaims as IdTokenClaims).family_name,
      name: (payload.idTokenClaims as IdTokenClaims).name,
      username: (payload.idTokenClaims as IdTokenClaims).preferred_username,
      isLoggedIn: true,
      roles: (payload.idTokenClaims as IdTokenClaims).roles,
      locale: 'en-US',
    };
    this._store.dispatch(setUser({ user }));
    this._store.dispatch(getUserFeatureFlags({ id: payload.tenantId }));
  }
}
