<mat-card class="my-3 min-h-full">
  <mat-card-header class="mb-4">
    <mat-card-title>
      {{ title }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
