<div class="part-search-modal">
  <div class="flex">
    <div class="flex-1 p-4">
      <form [formGroup]="searchFormGroup" (submit)="submit()">
        <div class="flex gap-2">
          <fip-form>
            <mat-form-field>
              <mat-label>Article Number</mat-label>
              <input
                data-cy="article-input-field"
                matInput
                #articleNumber
                placeholder="e.g. 41316072"
                formControlName="articleNumber"
                (keyup)="getCompanies(articleNumber.value)"
              />
            </mat-form-field>
          </fip-form>

          <fip-form class="flex-1">
            <mat-form-field
              class="w-full"
              [attr.disabled]="(companies$ | async)?.length === 0"
            >
              <mat-label>Company</mat-label>

              <mat-select
                data-cy="company-select"
                formControlName="companyCode"
                (valueChange)="searchResult= undefined"
              >
                <mat-option
                  *ngFor="let company of companies$ | async"
                  [value]="company.code"
                  >{{ company.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </fip-form>

          <fip-form formType="submit">
            <div
              class="spinner-wrapper inline-block"
              *ngIf="searchLoading$ | async"
            >
              <fip-loading-spinner class="scale-75"></fip-loading-spinner>
            </div>
            <fip-button
              data-cy="article-search-modal-btn"
              *ngIf="(searchLoading$ | async) !== true"
              [loading]="(searchLoading$ | async)!"
              (clickEvent)="submit()"
              label="Search"
              backgroundColor="black"
              textColor="white"
              classes="mt-3 px-3 py-2 sm:ml-3 sm:mt-0 sm:w-auto shadow-sm text-sm"
            ></fip-button>
          </fip-form>
        </div>
      </form>

      <ng-container *ngIf="!(searchLoading$ | async) && searchResult">
        <div class="pt-10" *ngIf="searchResult?.length; else noResult">
          <a
            [routerLink]="['production', 'stock', searchFormGroup.get('articleNumber')?.value, searchFormGroup.get('companyCode')?.value ]"
            class="text-blue-500"
          >
            {{searchResult.length}} items found</a
          >
        </div>
        <ng-template #noResult>
          <div data-cy="article-search-no-result" class="pt-10">
            No results found.
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div
      data-cy="serial-numbers-list"
      class="flex-1 self-stretch bg-gray-100 px-4 pb-10 pt-4"
    >
      <h4 class="text-base font-semibold leading-6 text-gray-900">Recent</h4>
      <ng-container
        *ngIf="!(savedArticles$ | async)?.length; else savedArticleContainer"
      >
        <p class="text-sm text-gray-500">You have no recent search.</p>
      </ng-container>
      <ng-template #savedArticleContainer>
        <button
          class="group mt-2 flex text-sm"
          *ngFor="let article of savedArticles$ | async"
          (click)="submit(article.articleNumber, article.companyCode )"
        >
          <p
            class="text-left text-sm font-semibold leading-6 text-gray-900 group-hover:text-gray-500"
          >
            {{ article.articleNumber }}, {{ article.companyName }}
          </p>
        </button>
      </ng-template>
    </div>
  </div>
</div>
