<plotly-plot [divId]="divId" [data]="data" [layout]="layout" [config]="config">
</plotly-plot>
<div *ngIf="exportable" class="flex w-full justify-end">
  <button
    (click)="handleExportClick()"
    class="mr-4 cursor-pointer text-xs text-gray-400 underline"
  >
    Export Graph
  </button>
</div>
