import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LineChartDataPoints, Spline } from '@interfaces';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';

@Component({
  selector: 'fip-histogram-chart',
  templateUrl: './histogram-chart.component.html',
  styleUrls: ['./histogram-chart.component.scss'],
})
export class HistogramChartComponent
  extends GenericChartComponent
  implements OnInit, OnChanges
{
  @Input() dataPoints: Array<LineChartDataPoints | Spline> = [];

  override refreshGraph() {
    const x = this.dataPoints[0].x;
    const y = this.dataPoints[0].y;
    const trace = this.createTrace(x, y);

    this.data = [];
    this.configXAxis(x);

    this.data = [trace, ...this.dataPoints.slice(1)];
    this.layout.shapes = [...this.shapes];

    this.layout = Object.assign({}, this.layout, {
      font: {
        size: 10,
      },
      autobinx: false,
      xbins: {
        end: 1,
        size: 0.1,
        start: 0,
      },
      xaxis: {
        title: {},
        range: 'auto',
        ticktext: this.xTicksLabels,
        tickvals: this.xTicksVals,
        fixedrange: true,
      },
      yaxis: {
        title: {},
        range: 'auto',
        fixedrange: true,
      },
    });
    this.configLegend();
  }

  configXAxis(x: number[]) {
    let minXValue = Math.min(...x);
    let maxXValue = Math.max(...x);

    this.dataPoints.forEach((shape) => {
      if ((shape as Spline).x) {
        minXValue = Math.min(...shape.x, minXValue);
        maxXValue = Math.max(...shape.x, maxXValue);
      }
    });

    this.shapes.forEach((shape) => {
      if (shape.x0 && shape.x1) {
        minXValue = Math.min(shape.x0, shape.x1, minXValue);
        maxXValue = Math.max(shape.x0, shape.x1, maxXValue);
      }
    });

    this.layout.xaxis.range = [minXValue, maxXValue];
    this.layout.xaxis.ticktext = x.map((val) => val.toString());
    this.layout.xaxis.tickvals = x;
  }

  createTrace(x: number[], y: number[]) {
    const trace = {
      name: 'Histogram',
      x,
      y,
      marker: {
        color: 'rgba(255, 100, 102, 0.7)',
        line: {
          color: 'rgba(255, 100, 102, 1)',
          width: 1,
        },
      },
      type: 'histogram',
      histfunc: 'max',
      xbins: {
        end: x[x.length - 1],
        size: (x[x.length - 1] - x[0]) / x.length,
        start: x[0],
      },
    };

    if (x.length === 1) {
      trace.xbins.size = 0.5;
      trace.xbins.end = x[0] + 0.5;
    }

    return trace;
  }
}
