import { Article } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const loadArticlesSuccess = createAction(
  '[Articles/API] Load Articles Success',
  props<{ articles: Article[] }>(),
);

export const getArticle = createAction(
  '[Article/API] Get Article',
  props<{
    articleId: string;
    batchIds: string[] | null;
    rootArticleId: string | null;
    rootBatchId: string | null;
  }>(),
);

export const setArticle = createAction(
  '[Article/API] Set Article',
  props<{
    article: Article | null;
  }>(),
);
