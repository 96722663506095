import {
  BomNode,
  IncomingOrPeriodicInspection,
  InlineInspection,
  ProcessParameter,
} from '@interfaces';
import { InspectionType, ProcessType } from '@enums';
import { createAction, props } from '@ngrx/store';
import { MeasurementsWrapper } from './batch.reducer';

export const loadBom = createAction(
  '[Batch/API] Load BOM',
  props<{ articleId: string | undefined; batchId: string | undefined }>(),
);

export const setBom = createAction(
  '[Batch/API] Set BOM',
  props<{ bom: BomNode }>(),
);

export const toggleBomLoading = createAction(
  '[Batch/API] Toggle BOM loading',
  props<{ loading: boolean }>(),
);

export const toggleInspectionsLoading = createAction(
  '[Batch/API] Toggle inspections loading',
  props<{
    loading: boolean;
    inspectionType:
      | 'incomingInspections'
      | 'inlineInspections'
      | 'periodicInspections';
  }>(),
);

export const loadInspections = createAction(
  '[Articles/API] Load Inspections',
  props<{
    inspectionType: InspectionType;
    articleId: BomNode['articleId'];
    batchIds: BomNode['batchId'][];
    rootArticleId: BomNode['articleId'];
    rootBatchId: BomNode['batchId'];
  }>(),
);

export const setInspections = createAction(
  '[Articles/API] Set Inspections',
  props<{
    inspectionType: InspectionType;
    items: IncomingOrPeriodicInspection[] | InlineInspection[] | null;
    articleId: IncomingOrPeriodicInspection['articleId'];
    batchIds: IncomingOrPeriodicInspection['batchId'];
    rootArticleId: IncomingOrPeriodicInspection['rootArticleId'];
    rootBatchId: IncomingOrPeriodicInspection['rootBatchId'];
  }>(),
);

export const getMeasurementsModalData = createAction(
  '[Articles/API] Get Measurements Modal Data',
  props<{
    productionOrderId: InlineInspection['productionOrderId'];
    nodeId: ProcessParameter['nodeId'];
    articleId: InlineInspection['articleId'];
    batchId: InlineInspection['batchId'];
    rootBatchId: InlineInspection['rootBatchId'];
    rootArticleId: InlineInspection['rootArticleId'];
    processType: ProcessType;
  }>(),
);

export const setMeasurementsModalData = createAction(
  '[Articles/API] Set Measurements Modal Data',
  props<{
    data: null | MeasurementsWrapper;
  }>(),
);

export const toggleMeasurementsModalLoading = createAction(
  '[Articles/API] Toggle Measurements Modal Loading',
  props<{
    loading: boolean;
  }>(),
);
