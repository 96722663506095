import { Component, DestroyRef, inject } from '@angular/core';
import { Monitor } from '@interfaces';
import { Store } from '@ngrx/store';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { createNewReductionInShipmentSizeFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';

@Component({
  selector: 'frontend-workspace-reduction-shipment-size-modal',
  templateUrl: './reduction-shipment-size-modal.component.html',
  styleUrl: './reduction-shipment-size-modal.component.css',
})
export class ReductionShipmentSizeModalComponent {
  destroyRef = inject(DestroyRef);

  formGroup = createNewReductionInShipmentSizeFormGroup();

  private readonly _store = inject(Store);

  submit() {
    const { name, recipientGroupId, edi, shipmentId } = this.formGroup.value;

    const monitor: Monitor = {
      partitionKey: 'v1',
      monitorType: 'reduction_shipment_size',
      name,
      recipientGroupId,
      edi,
      shipmentId,
      priorityLevel: 'P1',
    };

    this._store.dispatch(AlertingActions.upsertMonitor({ monitor }));
  }
}
