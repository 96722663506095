import { compareDates } from '@helpers';
import { ArticlePayload } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SEARCH_FEATURE_KEY, SearchState } from './search.reducer';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import * as dayjs from 'dayjs';

// Lookup the 'Search' feature state managed by NgRx
export const selectSearchState =
  createFeatureSelector<SearchState>(SEARCH_FEATURE_KEY);

// select saved serial numbers that have been stores in localstorage
export const selectSavedSerialNumbers = createSelector(
  selectSearchState,
  (state: SearchState) => [...(state.serialNumbers || [])].reverse(),
);

// select saved article payloads that have been stored in localstorage
export const selectSavedArticles = createSelector(
  selectSearchState,
  (state: SearchState) => [...(state.savedArticlePayloads || [])].reverse(),
);

export const selectSavedPlannedBomArticles = createSelector(
  selectSearchState,
  (state: SearchState) =>
    [...(state.plannedBomArticleSearchResults || [])].reverse(),
);

export const selectSavedSPCArticles = createSelector(
  selectSearchState,
  (state: SearchState) => [...(state.spcArticlePayloads || [])].reverse(),
);

export const searchLoading = (searchType: ModalKey) =>
  createSelector(
    selectSearchState,
    (state: SearchState) => state.loading[searchType],
  );

export const selectSerialSearchResultsBySerialNumber = (
  serialNumber: string,
) => {
  return createSelector(selectSearchState, (state: SearchState) => {
    const resultBySingleId = state.serialSearchResults?.find(
      (result) => result.singleItemId === serialNumber,
    );

    if (typeof resultBySingleId !== 'undefined') {
      return resultBySingleId;
    }

    return state.serialSearchResults?.find(
      (result) => result.externalSingleItemId === serialNumber,
    );
  });
};

export const selectArticleSearchResultsByArticlePayload = (
  articlePayload: ArticlePayload,
) => {
  return createSelector(
    selectSearchState,
    (state: SearchState) =>
      state.articleSearchResults?.find((result) => {
        // select the article search result
        // that matches the article payload's parameters
        // (articleNumber, productionFrom, productionUntil)
        return (
          Object.keys(articlePayload) as Array<keyof ArticlePayload>
        ).every((key) => {
          return dayjs.isDayjs(result[key]) &&
            dayjs.isDayjs(articlePayload[key])
            ? compareDates(
                result[key] as dayjs.Dayjs,
                articlePayload[key] as dayjs.Dayjs,
              )
            : result[key] === articlePayload[key];
        });
      }),
  );
};

export const selectPlannedBomArticleSearchResult = (
  articleNumber: string | null | undefined,
  companyCode: string | null | undefined,
) => {
  return createSelector(
    selectSearchState,
    (state: SearchState) =>
      state.plannedBomArticlePayload[articleNumber as string]?.[
        companyCode as string
      ],
  );
};

export const selectSPCArticleSearchResultsByArticlePayload = (
  articlePayload: ArticlePayload,
) => {
  return createSelector(
    selectSearchState,
    (state: SearchState) =>
      state.spcArticleSearchResults?.find((result) => {
        // select the article search result
        // that matches the article payload's parameters
        // (articleNumber, productionFrom, productionUntil)

        return (
          Object.keys(articlePayload) as Array<keyof ArticlePayload>
        ).every((key) => {
          return dayjs.isDayjs(result[key]) &&
            dayjs.isDayjs(articlePayload[key])
            ? compareDates(
                result[key] as dayjs.Dayjs,
                articlePayload[key] as dayjs.Dayjs,
              )
            : result[key] === articlePayload[key];
        });
      }),
  );
};

export const selectCompaniesByArticleId = (articleId: string) =>
  createSelector(
    selectSearchState,
    (state: SearchState) => state.companies[articleId],
  );
