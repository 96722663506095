import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { APP_CONFIG } from '@frontend-workspace/app-config';
import { SharedModule } from '@frontend-workspace/shared/src';
import { HotToastModule } from '@ngneat/hot-toast';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ArticleModule } from './article/article.module';
import { LoggedInLayoutComponent } from './core/logged-in-layout/logged-in-layout.component';
import { LoggedOutLayoutComponent } from './core/logged-out-layout/logged-out-layout.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { ProductionOrderNavigationComponent } from './core/production-order-navigation/production-order-navigation.component';
import { VerticalNavbarComponent } from './core/vertical-nav/vertical-nav.component';
import { SearchEffects } from './state/search/search.effects';
import {
  SEARCH_FEATURE_KEY,
  searchReducer,
} from './state/search/search.reducer';
import { AuthComponent } from './core/auth/auth.component';
import { USER_FEATURE_KEY, UserEffects, userReducer } from '@state/user';
import { AppEffects } from '@frontend-workspace/shared/src/lib/+state/app.effects';
import {
  FeatureFlagsService,
  featureFactory,
} from './services/feature-flags.service';
import { FeatureGuard, LoginGuard } from './guards';
import { MonitoringService } from './monitoring.service';
import { MatBadgeModule } from '@angular/material/badge';

const isIframe = window !== window.parent && !window.opener;
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoggedOutLayoutComponent,
    LoggedInLayoutComponent,
    NavbarComponent,
    VerticalNavbarComponent,
    ProductionOrderNavigationComponent,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HotToastModule.forRoot(),
    MatDialogModule, // imported here because it is used in hotToast
    RouterModule.forRoot(appRoutes, {
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true,
    }),
    StoreModule.forRoot(
      {
        [USER_FEATURE_KEY]: userReducer,
        [SEARCH_FEATURE_KEY]: searchReducer,
      },
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([SearchEffects, AppEffects, UserEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: false, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true,
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.aadClientId,
          authority: environment.aadAuthority,
          redirectUri: environment.aadRedirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [
            environment.apiProtectedResourceBase,
            [environment.apiProtectedResourceScope],
          ],
          [
            environment.azureFunctionProtectedResourceBase,
            [environment.apiProtectedResourceScope],
          ],
        ]),
      },
    ),
    SharedModule,
    ArticleModule,
    MatBadgeModule,
  ],
  providers: [
    LoginGuard,
    { provide: APP_CONFIG, useValue: environment },
    MsalGuard,
    {
      provide: environment.isTestEnv ? undefined : HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagsService],
      multi: true,
    },
    FeatureGuard,
    MonitoringService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
