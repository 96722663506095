import { Action, createReducer, on } from '@ngrx/store';
import { spcActions } from './spc.actions';
import {
  BatchHeader,
  BomNode,
  Company,
  Equipment,
  SPCChartsData,
  SPCGeneralData,
} from '@interfaces';
import { appendUniqueToList } from '@helpers';
import { InspectionsWrapper } from '../batch';

export const SPC_FEATURE_KEY = 'spc';

export interface SPCState {
  companies: { [key: string]: Company[] | null };
  equipments: Equipment[] | null;
  batches: BatchHeader[] | null;
  boms: BomNode[];
  generalData: SPCGeneralData | null;
  chartsData: SPCChartsData | null;
  generalDataLoading?: boolean;
  chartsDataLoading?: boolean;

  characteristics: { id: string; name: string }[];
  inspections: null | InspectionsWrapper[];
}

export const initialSPCState: SPCState = {
  companies: {},
  equipments: [],
  batches: [],
  boms: [],
  generalData: null,
  chartsData: null,
  generalDataLoading: false,
  chartsDataLoading: false,
  characteristics: [],
  inspections: null,
};

const reducer = createReducer(
  initialSPCState,
  on(spcActions.setCompanies, (state, { articleId, companies }) => {
    const newCompanies = Object.assign({}, state.companies);

    newCompanies[articleId] = companies;

    return {
      ...state,
      companies: newCompanies,
    };
  }),
  on(spcActions.setEquipments, (state, { equipments }) => {
    return {
      ...state,
      equipments,
    };
  }),
  on(spcActions.setBatchesByCompany, (state, { batches }) => {
    return {
      ...state,
      batches,
    };
  }),
  on(spcActions.setBOM, (state, { bom }) => ({
    ...state,
    boms: appendUniqueToList(state.boms, bom, ['articleId', 'batchId']),
  })),
  on(spcActions.setGeneralData, (state, { data }) => {
    return {
      ...state,
      generalData: data,
    };
  }),
  on(spcActions.setChartsData, (state, { data }) => {
    return {
      ...state,
      chartsData: data,
    };
  }),
  on(spcActions.toggleGeneralDataLoading, (state, { loading }) => {
    return {
      ...state,
      generalDataLoading: loading,
    };
  }),

  on(spcActions.setCharacteristics, (state, { characteristics }) => {
    return {
      ...state,
      characteristics,
    };
  }),
  on(spcActions.toggleChartDataLoading, (state, { loading }) => {
    return {
      ...state,
      chartsDataLoading: loading,
    };
  }),
  on(
    spcActions.setInspections,
    (state, { items, articleId, batchIds, rootArticleId, rootBatchId }) => {
      if (!articleId || !batchIds || !rootArticleId || !rootBatchId || !items) {
        return state;
      }

      const inspections = {
        articleId,
        batchIds,
        rootArticleId,
        rootBatchId,
        items,
      };

      return {
        ...state,
        inspections: appendUniqueToList(state.inspections, inspections, [
          'articleId',
          'rootBatchId',
        ]),
      };
    },
  ),
);

export function spcReducer(state: SPCState | undefined, action: Action) {
  return reducer(state, action);
}
