import { ChartType, InspectionType, ProcessType } from '@enums';
import {
  Article,
  BatchHeader,
  BomNode,
  Company,
  Equipment,
  IncomingOrPeriodicInspection,
  InlineInspection,
  SPCGeneralData,
} from '@interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const spcActions = createActionGroup({
  source: 'SPC API',

  events: {
    'Get Companies': props<{ articleId: Article['articleId'] }>(),
    'Set Companies': props<{
      articleId: Article['articleId'];
      companies: Company[] | null;
    }>(),

    'Get Equipments': props<{
      articleId: string;
      batchId: string;
      rootArticleId: string;
      rootBatchId: string;
      nodeId: string;
    }>(),
    'Set Equipments': props<{
      equipments: Equipment[] | null;
    }>(),

    'Get Batches By Company': props<{
      articleId: Article['articleId'];
      company: string | null;
      productionStart: string;
      productionEnd: string;
    }>(),
    'Set Batches By Company': props<{
      batches: BatchHeader[] | null;
    }>(),

    'Get BOM': props<{
      articleId: Article['articleId'];
      batchId: BatchHeader['batchId'];
    }>(),

    'Set BOM': props<{
      bom: BomNode;
    }>(),

    'Get General Data': props<{
      articleId: Article['articleId'];
      batchId: BatchHeader['batchId'];
      rootArticleId: Article['articleId'];
      rootBatchId: BatchHeader['batchId'];
      parameter: string;
      processType: string;
      inspectionType: InspectionType;
    }>(),

    'Toggle General Data Loading': props<{
      loading: boolean;
    }>(),

    'Set General Data': props<{
      articleId: Article['articleId'];
      batchId: BatchHeader['batchId'];
      rootArticleId: Article['articleId'];
      rootBatchId: BatchHeader['batchId'];
      data: SPCGeneralData | null;
    }>(),

    'Get Charts Data': props<{
      articleId: Article['articleId'];
      batchId: BatchHeader['batchId'];
      rootArticleId: Article['articleId'];
      rootBatchId: BatchHeader['batchId'];
      parameter: string | null;
      processType: string;
      subgroupSize: number;
      samplingInterval: string | null;
      numberOfGroups: number;
      productionOrderId?: string;
      inspectionType: InspectionType;
      chartType: ChartType;
      equipmentIds?: string[];
      isIntervalSampling?: boolean;
      start: string;
      end: string;
    }>(),

    'Set Charts Data': props<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any;
    }>(),

    'Get Process Names': props<{
      articleId: Article['articleId'];
      batchId: BatchHeader['batchId'];
      rootArticleId: Article['articleId'];
      rootBatchId: BatchHeader['batchId'];
      processType: ProcessType;
    }>(),

    'Get Characteristics': props<{
      articleId: Article['articleId'];
      batchId: BatchHeader['batchId'];
      rootArticleId: Article['articleId'];
      rootBatchId: BatchHeader['batchId'];
      inspectionType: InspectionType;
    }>(),

    'Set Characteristics': props<{
      characteristics: { id: string; name: string }[];
    }>(),

    'Toggle Chart Data Loading': props<{
      loading: boolean;
    }>(),

    'Load Inspections': props<{
      inspectionType: InspectionType;
      articleId: BomNode['articleId'];
      batchIds: BomNode['batchId'][];
      rootArticleId: BomNode['articleId'];
      rootBatchId: BomNode['batchId'];
      processType: ProcessType;
    }>(),

    'Set Inspections': props<{
      inspectionType: InspectionType;
      items: IncomingOrPeriodicInspection[] | InlineInspection[] | null;
      articleId: IncomingOrPeriodicInspection['articleId'];
      batchIds: IncomingOrPeriodicInspection['batchId'];
      rootArticleId: IncomingOrPeriodicInspection['rootArticleId'];
      rootBatchId: IncomingOrPeriodicInspection['rootBatchId'];
    }>(),
  },
});
