<div class="modal flex flex-col p-6">
  <div class="flex flex-1 items-center justify-center">
    <ul>
      <li class="list-inside list-disc" *ngFor="let file of data.files">
        <a [href]="file.url" target="_blank" class="text-blue-700">
          {{file.fileName}}
        </a>
      </li>
    </ul>
  </div>
</div>
