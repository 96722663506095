<div class="flex h-full w-full flex-col">
  <div
    class="h-screen w-full bg-cover"
    style="background-image: url('./assets/images/login_bg_updated.jpeg')"
  >
    <div class="error welcome flex h-auto w-fit flex-col bg-white p-10">
      <!-- New icon -->
      <img
        *ngIf="
          featureFlags.getFeatureAreaVisibility('newFipLogo') === 'enabled'
        "
        class="mb-9 h-12 w-fit"
        src="assets/images/logos/fip.svg"
        alt="fip_logo"
      />
      <!-- Legacy icon -->
      <img
        *ngIf="
          featureFlags.getFeatureAreaVisibility('newFipLogo') !== 'enabled'
        "
        class="mb-9 h-12 w-fit"
        src="assets/images/fip_logo.png"
        alt="fip_logo"
      />

      <p class="typo-h1 mb-4">Welcome</p>

      <button
        mat-flat-button
        color="primary"
        id="login-with-ms-button"
        class="px-6 py-3"
        (click)="login()"
      >
        Continue with Microsoft
      </button>
    </div>
    <span class="version-display">Version: {{ appVersion }}</span>
  </div>
</div>
