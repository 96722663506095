import { Injectable, inject } from '@angular/core';
import { EightDReport } from '@interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class EightDReportsService {
  private readonly _apiPath = 'eight-d-reports';
  private readonly _api = inject(ApiService);

  getEightDReports(params: {
    articleNum: string;
    batchId?: string;
    from: string;
    to: string;
    stationNum?: string;
    downtimeCategory?: string;
  }) {
    return this._api.get<EightDReport[]>(`${this._apiPath}`, {
      params,
    });
  }
}
