<button
  mat-flat-button
  color="primary"
  [class]="classes + ' ' + cursor + ' ' + whenInteract"
  [type]="type"
  (click)="onButtonClicked($event)"
  [disabled]="disabled"
  [title]="btnTitle"
  [class.spinner]="loading"
  [attr.data-cy]="dataCy"
>
  <fip-svg
    [bgColor]="backgroundColor"
    *ngIf="iconLeft !== undefined"
    [name]="iconLeft"
    [size]="size"
    [fill]="iconFillColor"
    [ngClass]="{
      'icon-small-left': small && displayLabel,
      'icon-simple-left': !small && displayLabel
    }"
  >
  </fip-svg>

  <img *ngIf="img" class="ml- 6 mr-2" [src]="img" />
  <label
    class="btn-label"
    [hidden]="!displayLabel"
    [class]="
      (loading ? 'text-dark-grey' : 'text-' + textColor) +
      ' ' +
      cursor +
      ' ' +
      labelTextClass
    "
  >
    {{ label }}
  </label>

  <fip-svg
    [bgColor]="backgroundColor"
    *ngIf="iconRight !== undefined"
    [name]="iconRight"
    [size]="size"
    [fill]="iconFillColor"
    [ngClass]="{
      'icon-small-right': small && displayLabel,
      'icon-simple-right': !small && displayLabel
    }"
  >
  </fip-svg>

  <mat-icon
    *ngIf="iconType === 'material'"
    [fontIcon]="iconRight!"
    [attr.iconPositionEnd]="!!iconRight"
  ></mat-icon>
</button>
