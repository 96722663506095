import { PartialRecord } from '@frontend-workspace/shared/src/lib/types';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { appendUniqueToList, formatToDate } from '@helpers';
import {
  ArticlePayload,
  ArticleSearchResult,
  Company,
  ProductionNumber,
  SerialSearchResult,
} from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as SearchActions from './search.actions';

export const SEARCH_FEATURE_KEY = 'search';
const SERIAL_NUMBERS_LOCALSTORAGE_KEY = 'savedSerialNumbers';
const ARTICLE_NUMBERS_LOCALSTORAGE_KEY = 'savedArticleNumbers';
const PLANNED_BOM_LOCALSTORAGE_KEY = 'savedPlannedBomArticleNumbers';
const SPC_ARTICLE_NUMBERS_LOCALSTORAGE_KEY = 'spcSavedArticleNumbers';
const MAX_ITEMS_IN_SEARCH_LIST = 10;

export interface SearchState {
  serialNumbers: string[] | null;
  savedArticlePayloads: Array<
    Omit<ArticlePayload, 'productionFrom' | 'productionUntil'> & {
      productionFrom: string;
      productionUntil: string;
    }
  > | null;
  serialSearchResults: SerialSearchResult[] | null;
  articleSearchResults: Array<
    ArticlePayload & {
      results: ArticleSearchResult[];
    }
  > | null;
  plannedBomArticleSearchResults: Array<{
    articleNumber: string;
    companyCode: string;
    companyName: string;
  }> | null;
  plannedBomArticlePayload: {
    [articleId: string]: { [companyCode: string]: ProductionNumber[] };
  };
  spcArticleSearchResults: Array<
    ArticlePayload & {
      results: ArticleSearchResult[];
    }
  > | null;
  spcArticlePayloads: Array<
    Omit<ArticlePayload, 'productionFrom' | 'productionUntil'> & {
      productionFrom: string;
      productionUntil: string;
    }
  > | null;
  loading: PartialRecord<ModalKey, boolean>; // has the Articles list been loaded
  error?: string | null | undefined; // last known error (if any)
  companies: { [key: string]: Company[] | null };
}

export interface SearchPartialState {
  readonly [SEARCH_FEATURE_KEY]: SearchState;
}

export const initialSearchState: SearchState = {
  // set initial required properties
  serialNumbers: getLocalStorageValues(SERIAL_NUMBERS_LOCALSTORAGE_KEY),
  serialSearchResults: null,
  savedArticlePayloads: getLocalStorageValues(ARTICLE_NUMBERS_LOCALSTORAGE_KEY),
  articleSearchResults: null,
  plannedBomArticleSearchResults: getLocalStorageValues(
    PLANNED_BOM_LOCALSTORAGE_KEY,
  ),
  plannedBomArticlePayload: {},
  spcArticlePayloads: getLocalStorageValues(
    SPC_ARTICLE_NUMBERS_LOCALSTORAGE_KEY,
  ),
  spcArticleSearchResults: null,
  loading: {},
  companies: {},
};

const reducer = createReducer(
  initialSearchState,
  on(
    SearchActions.loadPartBySerialNumberSuccess,
    (state, { serialNumber, serialSearchResult, save }) => {
      if (!serialSearchResult?.articleId) {
        return {
          ...state,
          serialSearchResults: null,
        };
      }

      const newState = {
        ...state,

        serialSearchResults: appendUniqueToList(
          state.serialSearchResults,
          serialSearchResult,
          ['singleItemId'],
        ),
      };

      if (save) {
        const serialNumberList = appendUniqueToList(
          state.serialNumbers,
          serialNumber,
        );
        if (serialNumberList.length > MAX_ITEMS_IN_SEARCH_LIST) {
          serialNumberList.shift();
        }
        // Save new serial numbers to local storage
        saveLocalStorageSearches(
          SERIAL_NUMBERS_LOCALSTORAGE_KEY,
          serialNumberList,
        );

        return {
          ...newState,
          serialNumbers: serialNumberList,
        };
      }

      return newState;
    },
  ),
  on(
    SearchActions.loadArticleSuccess,
    (state, { articlePayload, articleSearchResult }) => {
      if (!articleSearchResult?.length) {
        return {
          ...state,
          articleSearchResults: null,
        };
      }

      const savedArticles = appendUniqueToList(
        state.savedArticlePayloads,
        {
          ...articlePayload,
          productionFrom: formatToDate(articlePayload.productionFrom) || '',
          productionUntil: formatToDate(articlePayload.productionUntil) || '',
        },
        ['articleNumber', 'productionFrom', 'productionUntil'],
      );
      saveLocalStorageSearches(
        ARTICLE_NUMBERS_LOCALSTORAGE_KEY,
        Object.values(savedArticles),
      );

      return {
        ...state,
        savedArticlePayloads: Object.values(savedArticles),
        articleSearchResults: appendUniqueToList(
          state.articleSearchResults,
          {
            ...articlePayload,
            results: articleSearchResult,
          },
          ['articleNumber', 'productionFrom', 'productionUntil'],
        ),
      };
    },
  ),

  on(
    SearchActions.loadPlannedBomArticleSuccess,
    (state, { articleNumber, companyCode, companyName, results }) => {
      const articleNumberList = appendUniqueToList(
        state.plannedBomArticleSearchResults,
        { articleNumber, companyCode, companyName },
        ['articleNumber', 'companyCode', 'companyName'],
      );
      if (articleNumberList.length > MAX_ITEMS_IN_SEARCH_LIST) {
        articleNumberList.shift();
      }

      saveLocalStorageSearches(PLANNED_BOM_LOCALSTORAGE_KEY, articleNumberList);

      const newProductionStocks = JSON.parse(
        JSON.stringify(state.plannedBomArticlePayload),
      );

      if (!newProductionStocks[articleNumber]) {
        newProductionStocks[articleNumber] = {};
      }

      newProductionStocks[articleNumber][companyCode] = results || [];

      return {
        ...state,
        plannedBomArticleSearchResults: articleNumberList,
        plannedBomArticlePayload: newProductionStocks,
      };
    },
  ),

  on(SearchActions.reorderSearchResults, (state, { searchType, payload }) => {
    return {
      ...state,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [searchType]: moveToLastIndex(state[searchType]!, payload),
    };
  }),
  on(SearchActions.clearSearchResult, (state) => ({
    ...state,
    serialSearchResults: null,
  })),
  on(SearchActions.toggleSearchLoading, (state, { searchType, loading }) => ({
    ...state,
    loading: {
      ...state.loading,
      // Set loading state for the specific search type
      [searchType]: loading,
    },
  })),
  on(SearchActions.loadPartBySerialNumberFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SearchActions.setCompanies, (state, { articleNumber, companies }) => {
    const newCompanies = Object.assign({}, state.companies);

    newCompanies[articleNumber] = companies;

    return {
      ...state,
      companies: newCompanies,
    };
  }),
  on(
    SearchActions.loadSpcArticleSuccess,
    (state, { articlePayload, articleSearchResult }) => {
      if (!articleSearchResult?.length) {
        return {
          ...state,
          spcArticleSearchResults: null,
        };
      }

      const savedArticles = appendUniqueToList(
        state.spcArticlePayloads,
        {
          ...articlePayload,
          productionFrom: formatToDate(articlePayload.productionFrom) || '',
          productionUntil: formatToDate(articlePayload.productionUntil) || '',
        },
        ['articleNumber', 'productionFrom', 'productionUntil'],
      );
      saveLocalStorageSearches(
        SPC_ARTICLE_NUMBERS_LOCALSTORAGE_KEY,
        Object.values(savedArticles),
      );

      return {
        ...state,
        spcArticlePayloads: Object.values(savedArticles),
        spcArticleSearchResults: appendUniqueToList(
          state.spcArticleSearchResults,
          {
            ...articlePayload,
            results: articleSearchResult,
          },
          ['articleNumber', 'productionFrom', 'productionUntil'],
        ),
      };
    },
  ),
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}

/**
 * Get saved serial numbers from local storage
 */
export function getLocalStorageValues(key: string) {
  const savedValues = localStorage.getItem(key);

  if (savedValues) {
    return JSON.parse(savedValues);
  }
  return null;
}

/**
 * Save serial numbers to local storage
 * @param serialNumbers The serial numbers to save
 */
export function saveLocalStorageSearches(key: string, value: unknown[]) {
  if (!value?.length) {
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
}

function moveToLastIndex<T>(array: T[], item: T) {
  if (!array?.length) {
    return [item];
  }

  array = array?.filter((i) => JSON.stringify(item) !== JSON.stringify(i));
  return [...array, item];
}
