import {
  Alert,
  Monitor,
  PortalNotification,
  Recipient,
  RecipientGroup,
} from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';

export const ALERTING_FEATURE_KEY = 'alerting';

export interface AlertingState {
  alerts: null | Alert[];
  selectedAlert: Alert;
  notifications: null | PortalNotification[];
  monitors: Monitor[];
  selectedMonitor: Monitor;
  receiverGroups: null | RecipientGroup[];
  selectedReceiverGroup: null | RecipientGroup;
  receivers: Recipient[] | null;
  approverGroups: null | RecipientGroup[];
  approvers: Recipient[] | null;
  selectedApproverGroup: null | RecipientGroup;
  approverRecipients: Recipient[] | null;
  recipientGroupToDelete: RecipientGroup | null;
}

export const initialAlertingState: AlertingState = {
  selectedAlert: {} as Alert,
  alerts: null,
  notifications: null,
  monitors: [],
  selectedMonitor: {} as Monitor,
  receiverGroups: null,
  selectedReceiverGroup: null,
  receivers: null,
  approvers: null,
  approverGroups: null,
  selectedApproverGroup: null,
  approverRecipients: null,
  recipientGroupToDelete: null,
};

const reducer = createReducer(
  initialAlertingState,
  on(AlertingActions.fetchMonitorsSuccess, (state, { result }) => ({
    ...state,
    monitors: result,
  })),
  on(AlertingActions.fetchMonitorByIdSuccess, (state, { result }) => ({
    ...state,
    selectedMonitor: result,
  })),
  on(AlertingActions.fetchRecipientGroupsSuccess, (state, { result }) => ({
    ...state,
    receiverGroups: result,
  })),
  on(AlertingActions.fetchApproversGroupsSuccess, (state, { result }) => ({
    ...state,
    approverGroups: result,
  })),
  on(AlertingActions.fetchReceiversFromGroupSuccess, (state, { result }) => ({
    ...state,
    receivers: result,
  })),
  on(AlertingActions.fetchApproversFromGroupSuccess, (state, { result }) => ({
    ...state,
    approvers: result,
  })),
  on(AlertingActions.fetchAlertsSuccess, (state, { result }) => ({
    ...state,
    alerts: result,
  })),
  on(AlertingActions.fetchNotificationsSuccess, (state, { result }) => ({
    ...state,
    notifications: result,
  })),
  on(AlertingActions.fetchAlertByIdSuccess, (state, { result }) => ({
    ...state,
    selectedAlert: result,
  })),
  on(AlertingActions.createNewReceiverGroupSuccess, (state, { result }) => ({
    ...state,
    receiverGroups: result,
  })),
  on(AlertingActions.createNewApproverGroupSuccess, (state, { result }) => ({
    ...state,
    approverGroups: result,
  })),
  on(AlertingActions.setSelectedReceiverGroup, (state, { id }) => ({
    ...state,
    selectedReceiverGroup:
      state.receiverGroups?.find((group) => group.rowKey === id) || null,
  })),
  on(AlertingActions.setSelectedApproverGroup, (state, { id }) => ({
    ...state,
    selectedApproverGroup:
      state.approverGroups?.find((group) => group.rowKey === id) || null,
  })),
  on(AlertingActions.selectGroupToDelete, (state, { group }) => ({
    ...state,
    recipientGroupToDelete: group,
  })),
);

export function alertingReducer(
  state: AlertingState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
