<div class="fip-form__daterange-picker" *ngIf="formType === 'daterange-picker'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div class="fip-form__input" *ngIf="formType === 'input'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div class="fip-form__submit" *ngIf="formType === 'submit'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div class="fip-form__slide" *ngIf="formType === 'slide'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content><ng-content></ng-content></ng-template>
