import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SPCState, SPC_FEATURE_KEY } from './spc.reducer';

export const selectSPCState = createFeatureSelector<SPCState>(SPC_FEATURE_KEY);

export const selectCompaniesByArticleId = (articleId: string) =>
  createSelector(
    selectSPCState,
    (state: SPCState) => state.companies[articleId],
  );

export const selectEquipments = () =>
  createSelector(selectSPCState, (state: SPCState) => state.equipments);

export const selectSPCBatches = () =>
  createSelector(selectSPCState, (state: SPCState) => state.batches);

export const selectBomByArticleIdBatchId = (
  articleId: string,
  batchId: string,
) =>
  createSelector(selectSPCState, (state: SPCState) =>
    state.boms.find(
      (bom) => bom.articleId === articleId && bom.batchId === batchId,
    ),
  );

export const selectSPCGeneralData = () =>
  createSelector(selectSPCState, (state: SPCState) => state.generalData);

export const selectSPCChartsData = () =>
  createSelector(selectSPCState, (state: SPCState) => state.chartsData);

export const selectGeneralDataLoading = () =>
  createSelector(selectSPCState, (state: SPCState) => state.generalDataLoading);

export const selectChartsDataLoading = () =>
  createSelector(selectSPCState, (state: SPCState) => state.chartsDataLoading);

export const selectSPCCharacteristics = () =>
  createSelector(selectSPCState, (state: SPCState) => state.characteristics);

export const selectInspections = () =>
  createSelector(selectSPCState, (state: SPCState) => state.inspections);
