<div class="modal flex flex-col p-6">
  <div class="data-wrapper flex w-full flex-1 items-center">
    <ng-container *ngIf="loading$ | async; else dataLoaded">
      <fip-loading-spinner
        class="absolute left-2/4 top-2/4"
      ></fip-loading-spinner>
    </ng-container>

    <ng-template #dataLoaded>
      <ng-container *ngIf="dataState === ComponentDataState.HasData">
        <div class="parts-table-container">
          <fip-table
            class="inline-block w-full"
            [isCompact]="true"
            [data]="tableData"
            [columns]="columns"
            [displayedColumns]="displayedColumns"
            [exportable]="true"
            [paginated]="true"
            [pageSizeOptions]="[10, 20]"
          ></fip-table>
        </div>
      </ng-container>
      <ng-container *ngIf="dataState === ComponentDataState.NoData">
        <div class="mt-2 pl-4">No data available.</div>
      </ng-container>
    </ng-template>
  </div>
</div>
