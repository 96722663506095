/** @ref https://github.com/tabuckner/material-dayjs-adapter */

import { MatDateFormats } from '@angular/material/core';

/**
 * Custom Date-Formats and Adapter (using https://github.com/iamkun/dayjs)
 */
export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_WITH_TIME_FORMAT = 'MMMDDYYYY_HHmmss';
export const DATE_WITH_TIME_FORMAT_HUMAN_READABLE = 'YYYY-MM-DD hh:mm:ss';
