import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromArticles from '@state/article';
import { ArticlesEffects } from '@state/article';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromArticles.ARTICLES_FEATURE_KEY,
      fromArticles.articlesReducer,
    ),
    EffectsModule.forFeature([ArticlesEffects]),
  ],
})
export class ArticleModule {}
