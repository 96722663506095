import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@frontend-workspace/app-config';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';

@Component({
  selector: 'fip-support-modal',
  templateUrl: './support.modal.html',
  styleUrls: ['./support.modal.scss'],
})
export class SupportModalComponent implements OnInit {
  readonly modalKey: ModalKey = 'support';

  private readonly router = inject(Router);

  path: string;
  appVersion: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(APP_CONFIG) private readonly _appConfig: any,
  ) {}

  ngOnInit(): void {
    this.path = this.router.url;
    this.appVersion = this._appConfig.displayVersionNumber;
  }
}
