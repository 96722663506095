import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './app.reducer';

export const selectParts = createFeatureSelector<AppState>('app');

export const selectedPart = () =>
  createSelector(selectParts, (state: AppState) => state.selectedPart);

export const selectSingleItemsByProductionOrder = (productionOrderId: string) =>
  createSelector(
    selectParts,
    (state: AppState) => state.singleItemsByProductionOrder[productionOrderId],
  );

export const selectSingleItemsByProductionOrderLoading = () =>
  createSelector(
    selectParts,
    (state: AppState) => state.singleItemsByProductionOrderLoading,
  );
