import { Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { featureConfig } from '../interfaces';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly _apiPath = 'users';
  private readonly _api = inject(ApiService);

  getUserFeatureFlags(userId: string) {
    // Mock Feature Flags
    // TODO: Load these from the backend in the future
    const featureFlags: featureConfig = {
      production: undefined,
      SPC: undefined,
      support: undefined,
      newFipLogo: undefined,
      alerting: undefined,
      calibration: undefined,
    };
    return of({ data: featureFlags });
    // return this._api.get<featureConfig>(`${this._apiPath}/${userId}/roles`);
  }
}
