import { Injectable, inject } from '@angular/core';
import { ArticleService } from '@frontend-workspace/shared/src';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { SearchService } from '../../services/search.service';
import * as SearchActions from './search.actions';

@Injectable()
export class SearchEffects {
  private actions$ = inject(Actions);
  private readonly _searchService = inject(SearchService);
  private readonly _articleService = inject(ArticleService);
  private readonly _store = inject(Store);

  loadPartBySerialNumber$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SearchActions.loadPartBySerialNumber),
        tap(() =>
          this._store.dispatch(
            SearchActions.toggleSearchLoading({
              searchType: 'part-search_part-search',
              loading: true,
            }),
          ),
        ),
        switchMap(({ serialNumber, save }) =>
          from(
            this._searchService.searchSerialNumber({ serialNumber }).pipe(
              tap(() =>
                this._store.dispatch(
                  SearchActions.toggleSearchLoading({
                    searchType: 'part-search_part-search',
                    loading: false,
                  }),
                ),
              ),
              map((result) =>
                SearchActions.loadPartBySerialNumberSuccess({
                  serialNumber,
                  serialSearchResult: result.data,
                  save,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  toggleSearchLoading = (searchType: ModalKey, loading: boolean) => {
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType,
        loading,
      }),
    );
  };

  loadArticleByArticlePayload$ = createEffect((): Observable<Action> => {
    let searchType: ModalKey;

    return this.actions$.pipe(
      ofType(SearchActions.loadArticle),
      tap((payload) => {
        searchType = payload.searchType;

        return this.toggleSearchLoading(searchType, true);
      }),
      switchMap(({ articleNumber, productionFrom, productionUntil }) =>
        this._searchService
          .searchArticle({ articleNumber, productionFrom, productionUntil })
          .pipe(
            tap(() => this.toggleSearchLoading(searchType, false)),
            map((result) =>
              SearchActions.loadArticleSuccess({
                articlePayload: {
                  articleNumber,
                  productionFrom,
                  productionUntil,
                },
                articleSearchResult: result.data,
              }),
            ),
          ),
      ),
    );
  });

  loadPlannedBomArticleByArticlePayload$ = createEffect(
    (): Observable<Action> => {
      let searchType: ModalKey;
      let companyName: string;

      return this.actions$.pipe(
        ofType(SearchActions.loadPlannedBomArticle),
        tap((payload) => {
          searchType = payload.searchType;

          return this.toggleSearchLoading(searchType, true);
        }),
        switchMap(({ articleNumber, companyCode, companyName: name }) => {
          companyName = name;

          return this._articleService
            .getPlannedBom(articleNumber, companyCode)
            .pipe(
              tap(() => this.toggleSearchLoading(searchType, false)),
              map((result) =>
                SearchActions.loadPlannedBomArticleSuccess({
                  articleNumber,
                  companyCode,
                  companyName,
                  results: result.data,
                }),
              ),
            );
        }),
      );
    },
  );

  getCompanies$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(SearchActions.getCompanies),
      tap(() => {
        return this.toggleSearchLoading('production_planned_bom', true);
      }),
      switchMap(({ articleNumber }) =>
        from(this._articleService.getCompanies(articleNumber)).pipe(
          tap(() => this.toggleSearchLoading('production_planned_bom', false)),
          map((response) => response.data),
          map((companies) =>
            SearchActions.setCompanies({ articleNumber, companies }),
          ),
        ),
      ),
    );
  });

  loadSpcArticleByArticlePayload$ = createEffect((): Observable<Action> => {
    let searchType: ModalKey;

    return this.actions$.pipe(
      ofType(SearchActions.loadSpcArticle),
      tap((payload) => {
        searchType = payload.searchType;

        return this.toggleSearchLoading(searchType, true);
      }),
      switchMap(({ articleNumber, productionFrom, productionUntil }) =>
        this._searchService
          .searchArticle({ articleNumber, productionFrom, productionUntil })
          .pipe(
            tap(() => this.toggleSearchLoading(searchType, false)),
            map((result) =>
              SearchActions.loadSpcArticleSuccess({
                articlePayload: {
                  articleNumber,
                  productionFrom,
                  productionUntil,
                },
                articleSearchResult: result.data,
              }),
            ),
          ),
      ),
    );
  });
}
