<div class="measurements-modal">
  <div class="flex justify-center">
    <ng-container *ngIf="loading$ | async; else dataLoaded">
      <fip-loading-spinner
        class="absolute left-2/4 top-2/4"
      ></fip-loading-spinner>
    </ng-container>
    <ng-template #dataLoaded>
      <ng-container *ngIf="dataState === ComponentDataState.HasData">
        <div class="flex-1 p-4" *ngIf="chartData">
          <fip-line-chart
            [dataPoints]="chartData"
            [yTitle]="yAxisLabel"
            [xTicksVals]="xTicksVals"
            [xTicksLabels]="xTicksLabels"
            [width]="900"
            [setAutoMinYValue]="true"
          ></fip-line-chart>
        </div>
      </ng-container>
      <ng-container *ngIf="dataState === ComponentDataState.NoData">
        No data available.
      </ng-container>
    </ng-template>
  </div>
</div>
