import { Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BatchService {
  private readonly _apiPath = 'batches';
  private readonly _api = inject(ApiService);

  getBatchesByCompany(
    articleId: string,
    companyCode: string | null,
    productionFrom: string,
    productionTo: string,
  ) {
    const params: any = {
      articleNumber: articleId,
      productionFrom,
      productionTo,
    };

    if (companyCode) {
      params.companyCode = companyCode;
    }

    return this._api.get<any>(`${this._apiPath}`, {
      params,
    });
  }
}
