<div class="part-search-modal">
  <div class="flex">
    <div class="flex-1 p-4">
      <form
        class="flex gap-2"
        [formGroup]="searchFormGroup"
        (submit)="submit()"
      >
        <fip-form class="flex-1">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label data-cy="part-input-label">Part Number</mat-label>
            <input
              data-cy="part-input-field"
              matInput
              formControlName="partNumber"
              type="text"
              placeholder="P1234.."
            />
          </mat-form-field>
        </fip-form>
        <fip-form formType="submit">
          <fip-button
            dataCy="part-search-modal-btn"
            [loading]="(searchLoading$ | async)!"
            (clickEvent)="submit()"
            label="Search"
            backgroundColor="black"
            textColor="white"
            classes="mt-3 px-3 py-2 sm:ml-3 sm:mt-0 sm:w-auto shadow-sm text-sm"
          ></fip-button>
        </fip-form>
      </form>

      <ng-container *ngIf="!(searchLoading$ | async)">
        <div
          class="pt-2"
          data-cy="part-search-inline-result"
          *ngIf="searchResult; else noResult"
        >
          <div class="mx-auto max-w-7xl">
            <div class="mx-auto max-w-2xl lg:max-w-4xl">
              <div class="relative isolate flex flex-col gap-2 lg:flex-row">
                <div>
                  <div class="relative max-w-xl">
                    <h3 class="text-lg font-semibold leading-6 text-gray-900">
                      <span class="absolute inset-0"></span>
                      Found Part
                    </h3>
                  </div>
                  <div class="flex pt-6">
                    <div class="relative flex flex-col gap-x-4">
                      <div class="flex text-sm leading-6">
                        <p class="font-semibold text-gray-900">
                          <span class="absolute inset-0"></span>
                          Article ID:
                        </p>
                        <p class="ml-2 text-gray-600">
                          {{ searchResult.articleId }}
                        </p>
                      </div>
                      <div class="flex text-sm leading-6">
                        <p class="font-semibold text-gray-900">
                          <span class="absolute inset-0"></span>
                          Batch ID:
                        </p>
                        <p class="ml-2 text-gray-600">
                          {{ searchResult.batchId }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10 flex gap-2">
            <fip-button
              label="Inspections"
              backgroundColor="black"
              classes="px-3 py-1 text-sm hover:bg-accent-yellow group"
              (clickEvent)="navigateToInlineInspections()"
            ></fip-button>
          </div>
        </div>
        <ng-template #noResult>
          <div data-cy="part-search-inline-no-result" class="pt-2">
            No results found.
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div
      data-cy="serial-numbers-list"
      class="flex-1 self-stretch bg-gray-100 px-4 pb-10 pt-4"
    >
      <h4 class="text-base font-semibold leading-6 text-gray-900">Recent</h4>
      <ng-container
        *ngIf="
          !(savedSerialNumbers$ | async)?.length;
          else savedSerialNumbersContainer
        "
      >
        <p class="text-sm text-gray-500">You have no recent search.</p>
      </ng-container>
      <ng-template #savedSerialNumbersContainer>
        <button
          class="group mt-2 block text-sm"
          *ngFor="let serialNumber of savedSerialNumbers$ | async"
          (click)="submit(serialNumber)"
        >
          <p
            class="text-sm font-semibold leading-6 text-gray-900 group-hover:text-gray-500"
          >
            - {{ serialNumber }}
          </p>
        </button>
      </ng-template>
    </div>
  </div>
</div>
