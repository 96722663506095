<form [formGroup]="formGroup" (submit)="submit()">
  <fip-form>
    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input matInput type="string" formControlName="name" />
    </mat-form-field>
  </fip-form>
  <fip-form class="flex-1">
    <mat-form-field class="w-full">
      <mat-label>Recipients Group</mat-label>

      <mat-select formControlName="recipientGroupId">
        <mat-option
          *ngFor="let group of recipientGroups"
          [value]="group.rowKey"
          >{{ group.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </fip-form>
  <fip-form class="mb-5 !h-20">
    <mat-form-field>
      <mat-label>Add Shipment Id</mat-label>
      <input matInput type="string" formControlName="shipmentId" />
    </mat-form-field>
  </fip-form>
  <fip-form class="mb-5 !h-20">
    <mat-form-field>
      <mat-label>EDI value</mat-label>
      <input matInput type="number" formControlName="edi" />
    </mat-form-field>
  </fip-form>
  <fip-form formType="submit">
    <fip-button
      (clickEvent)="submit()"
      [label]="submitButtonText"
      backgroundColor="black"
      textColor="white"
      classes="!h-14 mt-5"
    ></fip-button>
  </fip-form>
</form>
