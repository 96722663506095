import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fip-wide-card',
  templateUrl: './wide-card.component.html',
  styleUrls: ['./wide-card.component.scss'],
})
export class WideCardComponent {
  @Input({ required: true }) heading!: string;
  @Input({ required: false }) subheading!: string;
  @Input() icon: string;
  @Input({ required: false }) borderBottom = true;
  @Input({ required: true }) buttonLabel!: string;
  @Input({ required: false }) disabled!: boolean;
  @Input({ required: false }) dataCy!: string;
  @Output() buttonClick = new EventEmitter();

  handleClick() {
    this.buttonClick.emit();
  }
}
