<div class="vertical-nav">
  <ng-container *ngFor="let item of menuItems">
    <a
      class="vertical-nav__link"
      data-cy="vertical-nav-link"
      [routerLink]="item.link || null"
      [matMenuTriggerFor]="item.children ? menu : null"
      [ngClass]="{
        disabled:
          item.productArea &&
          featureFlagService.getFeatureAreaVisibility(item.productArea) ===
            'disabled'
      }"
      (menuOpened)="item.children ? addClassToBody() : null"
      (menuClosed)="item.children ? removeClassFromBody() : null"
      *ngIf="
        !item.productArea ||
        featureFlagService.getFeatureAreaVisibility(item.productArea) !==
          'hidden'
      "
    >
      <mat-icon
        aria-hidden="false"
        [aria-label]="item.label"
        [fontIcon]="item.icon"
        *ngIf="item.icon"
      ></mat-icon>
      <span>{{ item.label }}</span>
    </a>

    <mat-menu data-cy="vertical-nav-menu" #menu="matMenu">
      <ng-container *ngFor="let child of item.children">
        <button
          mat-menu-item
          data-cy="vertical-nav-menu-item"
          [routerLink]="child.link"
          (click)="child.modalKey ? displayModal(child.modalKey) : $event"
          [disabled]="
            featureFlagService.getFeatureVisibility(child.feature) ===
            'disabled'
          "
          *ngIf="
            !child.feature ||
            featureFlagService.getFeatureVisibility(child.feature) !== 'hidden'
          "
        >
          <a>{{ child.label }}</a>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>
</div>
