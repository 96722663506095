import { Pipe, PipeTransform } from '@angular/core';
import { formatToDate } from '@helpers';
import { Dayjs } from 'dayjs';

@Pipe({ name: 'formatDate' })
export class DatePipe implements PipeTransform {
  transform(value: Dayjs) {
    if (!value) {
      return null;
    }

    return formatToDate(value);
  }
}
