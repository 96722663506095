import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectRecipientGroupToDelete } from '@core-app/state/alerting';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { RecipientGroupType } from '@frontend-workspace/shared/src/lib/interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'frontend-workspace-delete-recipient-group-modal',
  templateUrl: './delete-recipient-group-modal.component.html',
  styleUrls: ['./delete-recipient-group-modal.component.scss'],
})
export class DeleteRecipientGroupModalComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  id: null | string;
  groupType: RecipientGroupType | null;
  name: null | string;

  private readonly _store = inject(Store);

  ngOnInit(): void {
    this._store
      .select(selectRecipientGroupToDelete())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((group) => {
        this.id = group?.rowKey || null;
        this.name = group?.name || null;
        this.groupType = group?.type || null;
      });
  }

  submit() {
    if (!this.id || !this.groupType) {
      return;
    }
    this._store.dispatch(
      AlertingActions.deleteRecipientGroup({
        id: this.id,
        groupType: this.groupType,
      }),
    );
  }
}
