import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { PartsService } from '../services/parts.service';
import {
  fetchSingleItemsByProductionOrder,
  fetchSingleItemsByProductionOrderSuccess,
  toggleSingleItemsByProductionOrderLoading,
} from './app.actions';

@Injectable()
export class AppEffects {
  private actions$ = inject(Actions);

  private readonly _store = inject(Store);
  private readonly _partsService = inject(PartsService);

  fetchSingleItemsByProductionOrder$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchSingleItemsByProductionOrder),
        tap(() =>
          this._store.dispatch(
            toggleSingleItemsByProductionOrderLoading({ loading: true }),
          ),
        ),
        switchMap(({ productionOrderId }) =>
          from(
            this._partsService.getSingleItemsByProductionOrder(
              productionOrderId,
            ),
          ).pipe(
            map((response) => response.data),
            map((items) =>
              fetchSingleItemsByProductionOrderSuccess({
                items,
                productionOrderId,
              }),
            ),
            tap(() =>
              this._store.dispatch(
                toggleSingleItemsByProductionOrderLoading({ loading: false }),
              ),
            ),
          ),
        ),
      ),
  );
}
