// Transform an enum to an array of objects with label and value properties
// Useful for dropdowns for example
export function buildEnumList<T>(
  enumObject: T,
): { value: T[Extract<keyof T, string>]; label: string }[] {
  const enumList: Array<{
    value: T[Extract<keyof T, string>];
    label: string;
  }> = [];

  for (const type in enumObject) {
    if (isNaN(Number(type))) {
      enumList.push({ value: enumObject[type], label: type });
    }
  }
  return enumList;
}
