import { Injectable, inject } from '@angular/core';
import { ArticleService, BatchService } from '@frontend-workspace/shared/src';
import { InspectionType, ProcessType } from '@enums';
import { BomNode } from '@interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { spcActions } from './spc.actions';

@Injectable()
export class SPCEffects {
  private actions$ = inject(Actions);
  private readonly _articleService = inject(ArticleService);

  private readonly _batchService = inject(BatchService);
  private readonly _store = inject(Store);

  getCompanies$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getCompanies),
        switchMap(({ articleId }) =>
          from(this._articleService.getCompanies(articleId)).pipe(
            map((response) => response.data),
            map((companies) =>
              spcActions.setCompanies({ articleId, companies }),
            ),
          ),
        ),
      ),
  );

  getEquipment$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getEquipments),
        switchMap(
          ({ articleId, batchId, rootArticleId, rootBatchId, nodeId }) =>
            from(
              this._articleService.getEquipments(
                articleId,
                batchId,
                rootArticleId,
                rootBatchId,
                nodeId,
              ),
            ).pipe(
              map((response) => response.data),
              map((equipments) => spcActions.setEquipments({ equipments })),
            ),
        ),
      ),
  );

  getBatches$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getBatchesByCompany),
        switchMap(({ articleId, company, productionStart, productionEnd }) =>
          from(
            this._batchService.getBatchesByCompany(
              articleId,
              company,
              productionStart,
              productionEnd,
            ),
          ).pipe(
            map((response) => response.data),
            map((batches) => spcActions.setBatchesByCompany({ batches })),
          ),
        ),
      ),
  );

  loadBomByArticleIdBatchId$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getBOM),
        switchMap(({ articleId, batchId }) =>
          from(this._articleService.getBomTree(articleId, batchId)).pipe(
            map((bom) => bom.data!),
            map((bom) => spcActions.setBOM({ bom })),
          ),
        ),
      ),
  );

  getSPCGeneralData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getGeneralData),
        tap(() =>
          this._store.dispatch(
            spcActions.toggleGeneralDataLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(
          ({
            articleId,
            rootArticleId,
            rootBatchId,
            batchId,
            parameter,
            processType,
            inspectionType,
          }) =>
            from(
              this._articleService.getSPCGeneralData({
                articleId,
                rootArticleId,
                rootBatchId,
                batchId,
                parameter,
                processType,
                inspectionType,
              }),
            ).pipe(
              map((response) => response.data),
              map((data) =>
                spcActions.setGeneralData({
                  articleId,
                  batchId,
                  rootArticleId,
                  rootBatchId,
                  data,
                }),
              ),
              tap(() =>
                this._store.dispatch(
                  spcActions.toggleGeneralDataLoading({
                    loading: false,
                  }),
                ),
              ),
            ),
        ),
      ),
  );

  getSPCChartsData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getChartsData),
        tap(() =>
          this._store.dispatch(
            spcActions.toggleChartDataLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(
          ({
            articleId,
            rootArticleId,
            rootBatchId,
            batchId,
            parameter,
            processType,
            subgroupSize,
            samplingInterval,
            numberOfGroups,
            productionOrderId,
            inspectionType,
            chartType,
            equipmentIds,
            isIntervalSampling,
            start,
            end,
          }) =>
            from(
              this._articleService.getSPCChartsData({
                articleId,
                rootArticleId,
                rootBatchId,
                batchId,
                parameter,
                processType,
                subgroupSize,
                samplingInterval,
                numberOfGroups,
                productionOrderId,
                inspectionType,
                chartType,
                equipmentIds,
                isIntervalSampling,
                start,
                end,
              }),
            ).pipe(
              map((response) => response.data),
              map((data) =>
                spcActions.setChartsData({
                  data,
                }),
              ),
              tap(() =>
                this._store.dispatch(
                  spcActions.toggleChartDataLoading({
                    loading: false,
                  }),
                ),
              ),
            ),
        ),
      ),
  );

  getCharacteristics$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(spcActions.getCharacteristics),
        switchMap(
          ({
            articleId,
            batchId,
            rootArticleId,
            rootBatchId,
            inspectionType,
          }) =>
            from(
              this._articleService.getCharacteristics(
                articleId,
                batchId,
                rootArticleId,
                rootBatchId,
                inspectionType,
              ),
            ).pipe(
              map((response) => response.data),
              map((response) =>
                spcActions.setCharacteristics({
                  characteristics: response?.characteristics || [],
                }),
              ),
            ),
        ),
      ),
  );

  loadInspections$ = createEffect((): Observable<Action> => {
    let articleId: BomNode['articleId'];
    let batchIds: BomNode['batchId'][];
    let rootArticleId: BomNode['articleId'];
    let rootBatchId: BomNode['rootBatchId'];
    let inspectionType: InspectionType;
    let processType: ProcessType;

    const inspectionProperties: {
      [key in InspectionType]:
        | 'incomingInspections'
        | 'inlineInspections'
        | 'periodicInspections';
    } = {
      Incoming: 'incomingInspections',
      Inline: 'inlineInspections',
      Periodic: 'periodicInspections',
    };

    type methodName =
      | 'getIncomingInspections'
      | 'getInlineInspections'
      | 'getPeriodicInspections';

    return this.actions$.pipe(
      ofType(spcActions.loadInspections),
      tap((payload) => {
        articleId = payload.articleId;
        batchIds = payload.batchIds;
        rootArticleId = payload.rootArticleId;
        rootBatchId = payload.rootBatchId;
        inspectionType = payload.inspectionType;
        processType = payload.processType;
      }),
      switchMap((payload) => {
        // Convert to method name in article service
        const methodName: methodName = ('get' +
          inspectionProperties[payload.inspectionType][0].toUpperCase() +
          inspectionProperties[payload.inspectionType].slice(1)) as methodName;

        return from(
          this._articleService[methodName](
            payload.inspectionType,
            payload.articleId,
            payload.batchIds,
            payload.rootArticleId,
            payload.rootBatchId,
            processType,
          ),
        );
      }),

      map((result) =>
        spcActions.setInspections({
          inspectionType,
          items: result.data,
          articleId,
          batchIds: batchIds?.join(','),
          rootArticleId,
          rootBatchId,
        }),
      ),
    );
  });
}
