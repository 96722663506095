import { Component, EventEmitter, Input, Output } from '@angular/core';

export type SvgName =
  | 'check'
  | 'blank_icon'
  | 'close'
  | 'collapse'
  | 'date'
  | 'filter'
  | 'open_link'
  | 'search'
  | 'chevron_bottom'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_top'
  | 'settings'
  | 'help'
  | 'note'
  | 'image'
  | 'chart'
  | 'details'
  | 'home'
  | 'query_stats';

export type SvgRotation = '0' | '90' | '180' | '360';

@Component({
  selector: 'fip-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent {
  @Input() name?: SvgName | string = 'check';
  @Input() size? = '24';
  @Input() bgColor? = 'transparent';
  @Input() rotation?: SvgRotation = '0';
  @Input() fill? = 'black';
  @Input() classes? = '';

  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  onSvgClicked(event?: MouseEvent): void {
    this.clicked.emit(event);
  }
}
