import { Component, Input, OnInit } from '@angular/core';

export enum ToggleDataToShow {
  Chart,
  Table,
}

@Component({
  selector: 'fip-toggle-chart-table',
  templateUrl: './toggle-chart-table.component.html',
  styleUrls: ['./toggle-chart-table.component.scss'],
})
export class ToggleChartTableComponent implements OnInit {
  @Input({ required: true }) initialState?: ToggleDataToShow;
  @Input({ required: false }) disableChartBtn? = false;
  ToggleDataToShow = ToggleDataToShow;
  currentDataToShow?: ToggleDataToShow;

  ngOnInit() {
    this.currentDataToShow = this.initialState;
  }

  show(value: ToggleDataToShow) {
    this.currentDataToShow = value;
  }
}
