export const environment = {
  isTestEnv: false,
  production: true,
  defaultLocale: 'en-US',
  aadClientId: '043ab67d-bace-443c-8c7a-47f61f177aef',
  aadAuthority:
    'https://login.microsoftonline.com/a9b9b909-d389-4a5c-9c03-a7723fcd00e5',
  aadRedirectUri: 'https://dev.traceability.fraenkische.com/',
  azureFunctionsApiBaseUri: 'https://fip-functions-poc.azurewebsites.net/api',
  apiBaseUri: 'https://dev.traceability.fraenkische.com/api/v1',
  apiProtectedResourceBase: 'https://dev.traceability.fraenkische.com/api/*',
  azureFunctionProtectedResourceBase: '',
  apiProtectedResourceScope:
    'api://043ab67d-bace-443c-8c7a-47f61f177aef/Access.All',
  appInsights: {
    instrumentationKey: 'ce32f78a-d1de-4603-9d4e-24b88a013690',
    connectionString:
      'InstrumentationKey=ce32f78a-d1de-4603-9d4e-24b88a013690;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
  displayVersionNumber: 'v3.4.0',
  featureFlags: {
    production: {
      visibility: 'enabled',
      flags: {
        '8D Reports': 'enabled',
        oee: 'enabled',
      },
    },
    support: {
      visibility: 'enabled',
    },
    newFipLogo: {
      visibility: 'enabled',
    },
    alerting: {
      visibility: 'enabled',
    },
    SPC: {
      visibility: 'enabled',
      flags: {
        NEW_SPC_CHARTS: 'enabled',
        CAPABILITY_HISTOGRAM_CHART: 'enabled',
        LAST_SUBGROUPS_CHART: 'enabled',
        CAPABILITY_PLOT_CHART: 'enabled',
        PROBABILITY_CHART: 'enabled',
        HISTOGRAM_LINES: 'hidden',
      },
    },
    calibration: {
      visibility: 'disabled',
    },
  },
  validReceiversDomains: ['berlin-bytes.de', 'gmail.com', 'btelligent.com'],
  validApproversDomains: ['berlin-bytes.de', 'btelligent.com'],
};
