<div class="support-modal h-full">
  <div class="flex h-full flex-col p-10">
    <div class="flex-grow">
      <!-- @TODO -->
    </div>
    <ul class="my-4 text-xs text-gray-300">
      <li>Version: {{appVersion}}</li>
      <li>Resource: {{path}}</li>
    </ul>
  </div>
</div>
