import { Injectable, inject } from '@angular/core';
import {
  PartsAssemblyDTO,
  PartsLeakCheckDTO,
  PartsPositionCheckImageDTO,
  PartsSummaryDTO,
} from '@interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PartsService {
  private readonly _apiPath = 'single-items';
  private readonly _api = inject(ApiService);

  getPartById(id: string) {
    return this._api.get<PartsSummaryDTO>(`${this._apiPath}/${id}`);
  }

  getPartPositionCheckImages(id: string) {
    return this._api.get<PartsPositionCheckImageDTO[]>(
      `${this._apiPath}/${id}/position-check-images`,
    );
  }

  getPartLeakCheck(id: string) {
    return this._api.get<PartsLeakCheckDTO[]>(
      `${this._apiPath}/${id}/leak-check`,
    );
  }

  getAssembly(id: string) {
    return this._api.get<PartsAssemblyDTO[]>(`${this._apiPath}/${id}/assembly`);
  }

  getSingleItemsByProductionOrder(id: string) {
    return this._api.get<PartsSummaryDTO[]>(
      `production-orders/${id}/single-items`,
    );
  }
}
