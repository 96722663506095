import { inject, Injectable } from '@angular/core';
import { selectFeatures } from '@core-app/state/user';
import { featureConfig, featureVisibility, productArea } from '@interfaces';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';

export const featureFactory =
  (featureFlagsService: FeatureFlagsService) => () =>
    featureFlagsService;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private readonly _store = inject(Store);
  features$ = this._store.select(selectFeatures);
  features: Partial<featureConfig> = environment.featureFlags as featureConfig;

  constructor() {
    this.features$.subscribe((features) => {
      if (!features) {
        return;
      }

      for (const area in features) {
        // Make sure the product area visibility properties are also overridden
        if (features[area as productArea]?.visibility) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.features[area as productArea]!.visibility =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            features[area as productArea]!.visibility;
        }

        for (const flag in features[area as productArea]?.flags) {
          if (this.features[area as productArea]?.flags) {
            const flags = features[area as productArea]?.flags;

            if (
              flags &&
              this.features &&
              this.features[area as productArea] &&
              this.features[area as productArea]?.flags
            ) {
              // Note: Typescript didn't like the following line, so I had to add the non-null assertion operator
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.features[area as productArea]!.flags![flag] = flags[flag];
            }
          }
        }
      }
    });
  }

  getFeatureAreaVisibility(area?: productArea): featureVisibility {
    if (!area) {
      return 'enabled';
    }

    if (!this.features) {
      return 'hidden';
    }

    return this.features[area as productArea]?.visibility || 'hidden';
  }

  getFeatureVisibility(feature?: string): featureVisibility {
    if (!feature) {
      return 'hidden';
    }

    for (const area in this.features) {
      if (this.features[area as productArea]?.flags?.[feature]) {
        return this.features[area as productArea]?.flags?.[feature] || 'hidden';
      }
    }

    return 'hidden';
  }
}
