import {
  BomNode,
  IncomingOrPeriodicInspection,
  InlineInspection,
  Measurement,
  SimpleMeasurement,
} from '@interfaces';
import { appendUniqueToList } from '@helpers';
import { Action, createReducer, on } from '@ngrx/store';
import * as BatchActions from './batch.actions';
import { InspectionType, ProcessType } from '@enums';

export const BATCH_FEATURE_KEY = 'batch';

export interface InspectionsWrapper {
  articleId: string;
  batchIds: string;
  rootArticleId: string;
  rootBatchId: string;
  items: IncomingOrPeriodicInspection[] | InlineInspection[];
}

export interface MeasurementsWrapper {
  nodeId: string;
  processType: ProcessType;
  displayName: string;
  unit: string;
  measurements: SimpleMeasurement[] | Measurement[];
}

export interface BatchState {
  boms: BomNode[];
  bomLoading: boolean;
  incomingInspections: null | InspectionsWrapper[];
  inlineInspections: null | InspectionsWrapper[];
  periodicInspections: null | InspectionsWrapper[];
  incomingInspectionsLoading: boolean;
  inlineInspectionsLoading: boolean;
  periodicInspectionsLoading: boolean;
  measurementsModalData: null | MeasurementsWrapper;
  measurementsModalLoading?: boolean;
}

export interface BatchPartialState {
  readonly [BATCH_FEATURE_KEY]: BatchState;
}

export const initialBatchState: BatchState = {
  boms: [],
  bomLoading: false,
  incomingInspections: null,
  inlineInspections: null,
  periodicInspections: null,
  incomingInspectionsLoading: false,
  inlineInspectionsLoading: false,
  periodicInspectionsLoading: false,
  measurementsModalData: null,
  measurementsModalLoading: false,
};

// Used to make the inspections handling more generic
const inspectionProperties: {
  [key in InspectionType]:
    | 'incomingInspections'
    | 'inlineInspections'
    | 'periodicInspections';
} = {
  Incoming: 'incomingInspections',
  Inline: 'inlineInspections',
  Periodic: 'periodicInspections',
};

const reducer = createReducer(
  initialBatchState,
  on(BatchActions.setBom, (state, { bom }) => ({
    ...state,
    boms: appendUniqueToList(state.boms, bom, ['articleId', 'batchId']),
  })),
  on(
    BatchActions.setInspections,
    (
      state,
      {
        inspectionType,
        items,
        articleId,
        batchIds,
        rootArticleId,
        rootBatchId,
      },
    ) => {
      const inspections = {
        articleId,
        batchIds,
        rootArticleId,
        rootBatchId,
        items,
      };

      const property = inspectionProperties[inspectionType];

      return {
        ...state,
        [property]: appendUniqueToList(state[property], inspections, [
          'articleId',
          'rootBatchId',
        ]),
      };
    },
  ),
  on(BatchActions.toggleBomLoading, (state, { loading }) => {
    return {
      ...state,
      bomLoading: loading,
    };
  }),
  on(
    BatchActions.toggleInspectionsLoading,
    (state, { loading, inspectionType }) => {
      return {
        ...state,
        [`${inspectionType}Loading`]: loading,
      };
    },
  ),
  on(BatchActions.toggleMeasurementsModalLoading, (state, { loading }) => {
    return {
      ...state,
      measurementsModalLoading: loading,
    };
  }),
  on(BatchActions.setMeasurementsModalData, (state, { data }) => {
    if (!data) {
      return state;
    }

    return {
      ...state,
      measurementsModalData: data,
    };
  }),
);

export function batchReducer(state: BatchState | undefined, action: Action) {
  return reducer(state, action);
}
