import * as dayjs from 'dayjs';

export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_FORMAT_FOR_PIPE = 'YYYY/MM/dd';
export const DATE_AND_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const TOASTER_FADE_DURATION = 7000;

/**
 * Append an item to the list by checking if it's not duplicated
 * @param array The array to append the item to
 * @param item The item to append
 **/
export function appendUniqueToList<T>(
  array: T[] | null,
  item: T,
  uniqueKeys?: Array<keyof T>,
) {
  // If the array is empty, return a new array with the item
  if (!array || !array.length) {
    return [item];
  }

  // Check if the array already contains an object with the same id
  const duplicate = array?.find((i) =>
    uniqueKeys
      ? uniqueKeys.every((uniqueKey) => i[uniqueKey] === item[uniqueKey])
      : i === item,
  );

  // append item to array if there is no duplicate
  if (!duplicate) {
    return array?.concat(item);
  }

  if (uniqueKeys?.length) {
    return updateSimilarItemByKeys(array, item, uniqueKeys);
  }

  return array?.map((i) => (i === item ? item : i));
}

function updateSimilarItemByKeys<T>(
  array: T[],
  item: T,
  uniqueKeys: Array<keyof T>,
) {
  return array.map((i) => {
    const isSimilar = uniqueKeys.every((key) => i[key] === item[key]);
    return isSimilar ? item : i;
  });
}

/**
 * Format a dayjs object to a date string
 * @param date dayjs object to format
 * @returns formatted date string
 */
export function formatToDate(
  date?: dayjs.Dayjs | string,
  dateFormat = DATE_FORMAT,
) {
  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    date = dayjs(date);
  }

  return date.format(dateFormat);
}

/**
 * Parse a string using a specific date format
 * @param date as a string
 * @param format as a string to be used to format
 * @returns formatted dayjs object\
 */
export function parseDate(date?: string, format?: string) {
  if (!date) {
    return null;
  }

  return dayjs(date, format);
}

/**
 * compare dates using dayjs
 */
export function compareDates(date1?: dayjs.Dayjs, date2?: dayjs.Dayjs) {
  if (!date1 || !date2) {
    return false;
  }

  return date1.diff(date2, 'milliseconds') === 0;
}

/**
 * Remove duplicates from nested object
 * if 2 properties have the same uniqueKey, only the one with children will be kept
 * (mostly used in bom-tree (batch-bom.component.ts))
 * */
export function removeDuplicatesFromNestedObject<T extends { children: T[] }>(
  nestedObj: T,
  uniqueKey: keyof T,
) {
  function removeDuplicates(obj: T, children: T[]) {
    let uniqueChildren: T[] = [];
    children.forEach((child) => {
      if (child.children?.length > 0) {
        child = removeDuplicates(child, child.children);
      }

      const isDuplicate = uniqueChildren.some(
        (uniqueChild) => uniqueChild[uniqueKey] === child[uniqueKey],
      );
      if (!isDuplicate) {
        uniqueChildren.push(child);
      } else if (isDuplicate && child.children?.length > 0) {
        const duplicatedChild = uniqueChildren.find(
          (c) => c[uniqueKey] === child[uniqueKey],
        );
        uniqueChildren = uniqueChildren.filter(
          (c) => c[uniqueKey] !== duplicatedChild?.[uniqueKey],
        );
        uniqueChildren.push(child);
      }
    });

    return {
      ...obj,
      children: uniqueChildren,
    };
  }

  nestedObj = removeDuplicates(nestedObj, nestedObj.children);
  return nestedObj;
}

/**
 * Find a child object in a nested object
 * @param nestedObj The root object
 * @param uniqueKey The key used to find the child object
 * @param uniqueValue The value used to find the child object
 * @returns The child object if found, otherwise undefined
 */
export function findChildFromNestedObject<T extends { children: T[] }>(
  nestedObj: T,
  uniqueKey: keyof T,
  uniqueValue: T[typeof uniqueKey],
) {
  if (nestedObj[uniqueKey] === uniqueValue) {
    // Return the root object if the value is found in the root object
    return nestedObj;
  }

  function find(children: T[]) {
    let foundChild: T | undefined;

    for (const child of children) {
      if (child[uniqueKey] === uniqueValue) {
        foundChild = child;
        break; // No need to continue searching if found
      }

      if (child.children?.length > 0) {
        foundChild = find(child.children);
        if (foundChild) {
          break; // No need to continue searching if found
        }
      }
    }

    return foundChild;
  }

  return find(nestedObj.children);
}

/**
 * Find children objects in a nested object
 * @param nestedObj The root object
 * @param uniqueKey The key used to find the children objects
 * @param uniqueValue The value used to find the children objects
 * @returns An array of children objects if found, otherwise an empty array
 */
export function findChildrenFromNestedObject<T extends { children: T[] }>(
  nestedObj: T,
  uniqueKey: keyof T,
  uniqueValue: T[typeof uniqueKey],
): T[] {
  const foundChildren: T[] = [];

  function find(children: T[]) {
    for (const child of children) {
      if (child[uniqueKey] === uniqueValue) {
        foundChildren.push(child);
      }

      if (child.children?.length > 0) {
        find(child.children);
      }
    }
  }

  find([nestedObj]);

  return foundChildren;
}

/**
 * Check if it's a valid email
 * @param email string to be checked if it is an email
 * @returns if it's a valid email or not
 */
export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Checks if an email is in a given domain list
 * @param email email to be checked
 * @param validEmailDomains domain list
 * @returns if email is in the domain list
 */
export function emailMatchDomainList(
  email: string,
  validEmailDomains: string[],
): boolean {
  const emailParts = email.split('@');
  if (emailParts.length !== 2) {
    return false;
  }
  const domain = emailParts[1];
  if (!validEmailDomains.includes(domain)) {
    return false;
  }
  return true;
}
