import { Component, Input } from '@angular/core';

@Component({
  selector: 'fip-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  @Input() formType: 'daterange-picker' | 'input' | 'submit' | 'slide' =
    'input';
}
