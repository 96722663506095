<div>
  <div
    *ngIf="
      _featureFlagService.getFeatureAreaVisibility('support') === 'enabled'
    "
    class="fixed bottom-4 right-4 z-40"
  >
    <button
      mat-fab
      color="primary"
      class="!bg-white"
      (click)="openSupportDialog()"
    >
      <mat-icon>support</mat-icon>
    </button>
  </div>
  <div class="z-20">
    <router-outlet></router-outlet>
  </div>
</div>
