import { BomNode } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BATCH_FEATURE_KEY, BatchState } from './batch.reducer';

// Lookup the 'Batch' feature state managed by NgRx
export const selectBatchState =
  createFeatureSelector<BatchState>(BATCH_FEATURE_KEY);

export const selectBomByArticleIdBatchId = (
  articleId: string | undefined,
  batchId: string | undefined,
) =>
  createSelector(selectBatchState, (state: BatchState) =>
    state.boms.find(
      (bom) => bom?.articleId === articleId && bom.batchId === batchId,
    ),
  );

export const selectIncomingInspections = (
  rootBatchId: BomNode['rootBatchId'],
  rootArticleId: BomNode['articleId'],
  batchIds: BomNode['batchId'],
  articleId: BomNode['articleId'],
) =>
  createSelector(selectBatchState, (state: BatchState) => {
    return state.incomingInspections?.find(
      (inspections) =>
        inspections?.rootBatchId === rootBatchId &&
        inspections?.rootArticleId === rootArticleId &&
        inspections?.batchIds === batchIds &&
        inspections?.articleId === articleId,
    )?.items;
  });

export const selectInlineInspections = (
  rootBatchId: BomNode['rootBatchId'],
  rootArticleId: BomNode['articleId'],
  batchIds: BomNode['batchId'],
  articleId: BomNode['articleId'],
) =>
  createSelector(selectBatchState, (state: BatchState) => {
    return state.inlineInspections?.find(
      (inspections) =>
        inspections?.rootBatchId === rootBatchId &&
        inspections?.rootArticleId === rootArticleId &&
        inspections?.batchIds === batchIds &&
        inspections?.articleId === articleId,
    )?.items;
  });

export const selectPeriodicInspections = (
  rootBatchId: BomNode['rootBatchId'],
  rootArticleId: BomNode['articleId'],
  batchIds: BomNode['batchId'],
  articleId: BomNode['articleId'],
) =>
  createSelector(selectBatchState, (state: BatchState) => {
    return state.periodicInspections?.find(
      (inspections) =>
        inspections?.rootBatchId === rootBatchId &&
        inspections?.rootArticleId === rootArticleId &&
        inspections?.batchIds === batchIds &&
        inspections?.articleId === articleId,
    )?.items;
  });

export const selectBatchLoading = () =>
  createSelector(selectBatchState, (state: BatchState) => state.bomLoading);

export const selectIncomingInspectionsLoading = () =>
  createSelector(
    selectBatchState,
    (state: BatchState) => state.incomingInspectionsLoading,
  );

export const selectInlineInspectionsLoading = () =>
  createSelector(
    selectBatchState,
    (state: BatchState) => state.inlineInspectionsLoading,
  );

export const selectPeriodicInspectionsLoading = () =>
  createSelector(
    selectBatchState,
    (state: BatchState) => state.periodicInspectionsLoading,
  );

export const selectMeasurementsModalData = () =>
  createSelector(
    selectBatchState,
    (state: BatchState) => state.measurementsModalData,
  );

export const selectMeasurementsModalLoading = () =>
  createSelector(
    selectBatchState,
    (state: BatchState) => state.measurementsModalLoading,
  );
