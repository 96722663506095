import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search/search.actions';
import { SearchState } from '@core-app/state/search/search.reducer';
import {
  searchLoading,
  selectSavedSerialNumbers,
  selectSerialSearchResultsBySerialNumber,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { SerialSearchResult } from '@interfaces';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'fip-part-search-modal',
  templateUrl: './part-search.modal.html',
  styleUrls: ['./part-search.modal.scss'],
})
export class PartSearchModalComponent implements OnInit, OnDestroy {
  readonly modalKey: ModalKey = 'part-search_part-search';
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store<SearchState>);
  private readonly _router = inject(Router);

  savedSerialNumbers$ = this._store.select(selectSavedSerialNumbers);
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: SerialSearchResult;
  searchFormGroup = new FormGroup({
    partNumber: new FormControl('', [Validators.required]),
  });

  ngOnInit() {
    // Disable form group while loading...
    this.searchLoading$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((loading) => {
        if (loading) {
          this.searchFormGroup.disable();
        } else {
          this.searchFormGroup.enable();
        }
      });
  }

  submit(serialNumber?: string) {
    if (serialNumber) {
      this.searchFormGroup.get('partNumber')?.setValue(serialNumber);
    }

    const param = serialNumber ?? this.searchFormGroup.value.partNumber;
    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    const partNumber = this.searchFormGroup.value.partNumber;

    if (partNumber) {
      // Listen to search result from ngrx store
      this._store
        .select(selectSerialSearchResultsBySerialNumber(partNumber))
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          tap((data) => {
            if (!data) {
              this._store.dispatch(
                SearchActions.loadPartBySerialNumber({
                  serialNumber: param,
                  save: true,
                }),
              );
            }
          }),
          filter((data) => !!data),
          tap(() =>
            this._store.dispatch(
              SearchActions.reorderSearchResults({
                searchType: 'serialNumbers',
                payload: partNumber,
              }),
            ),
          ),
        )
        .subscribe((data) => (this.searchResult = data));
    }
  }

  navigateToSummary() {
    this._router.navigate(['parts', this.searchResult?.singleItemId]);
  }

  ngOnDestroy() {
    // Clear search retsult before closing modal
    this.searchResult = undefined;
    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
