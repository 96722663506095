import { Injectable, inject } from '@angular/core';
import { ArticleService } from '@frontend-workspace/shared/src';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, map, switchMap } from 'rxjs';
import { getArticle, setArticle } from './articles.actions';

@Injectable()
export class ArticlesEffects {
  private actions$ = inject(Actions);
  private readonly _articleService = inject(ArticleService);
  private readonly _store = inject(Store);

  getArticle$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(getArticle),
        switchMap(({ articleId, batchIds, rootArticleId, rootBatchId }) =>
          from(
            this._articleService.getArticle(
              articleId,
              batchIds,
              rootArticleId,
              rootBatchId,
            ),
          ).pipe(
            map((response) => response.data),
            map((article) => setArticle({ article })),
          ),
        ),
      ),
  );
}
