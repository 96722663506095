import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchState } from '@core-app/state/search/search.reducer';
import { selectBomByArticleIdBatchId, spcActions } from '@core-app/state/spc';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { BomNode } from '@interfaces';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'fip-article-selector-modal',
  templateUrl: './article-selector.modal.html',
  styleUrls: ['./article-selector.modal.scss'],
})
export class ArticleSelectorModalComponent implements OnInit {
  readonly modalKey: ModalKey = 'article_selector';
  private readonly _store = inject(Store<SearchState>);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  bom$: Observable<BomNode | undefined>;
  articleId: string;
  batchIds: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      articleId: string;
      batchId: string;
    },
  ) {}

  ngOnInit() {
    this.bom$ = this._store
      .select(
        selectBomByArticleIdBatchId(this.data.articleId, this.data.batchId),
      )
      .pipe(
        tap((bom) => {
          if (!bom) {
            this._store.dispatch(
              spcActions.getBOM({
                articleId: this.data.articleId,
                batchId: this.data.batchId,
              }),
            );
          }
        }),
      );
  }

  articleSelected({
    articleId,
    batchIds,
  }: {
    articleId: string;
    batchIds: string;
  }) {
    this.articleId = articleId;
    this.batchIds = batchIds;
  }

  submit() {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { articleId: this.articleId, batchId: this.batchIds },
      queryParamsHandling: 'merge',
    });
  }
}
