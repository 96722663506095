<div class="article-selector-modal flex flex-col">
  <div class="flex flex-1 justify-center">
    <fip-batch-bom
      [bom]="(bom$ | async)!"
      class="flex"
      (selectArticle)="articleSelected($event)"
    ></fip-batch-bom>
  </div>

  <div class="action-buttons flex justify-end p-4">
    <div *ngIf="articleId">Selected article ID: {{articleId}}</div>
    <fip-form formType="submit" class="ml-auto">
      <fip-button
        (clickEvent)="submit()"
        label="OK"
        backgroundColor="black"
        textColor="white"
        classes="mt-3 px-3 py-2 sm:ml-3 sm:mt-0 sm:w-auto shadow-sm text-sm font-semibold"
      ></fip-button>
    </fip-form>
  </div>
</div>
