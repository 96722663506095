<div class="modal fip-dialog-success-body w-full" tabindex="-1" role="dialog">
  <div class="modal-dialog w-full" role="document">
    <div class="modal-content w-full overflow-hidden">
      <div
        class="h-1/7 flex w-full justify-between"
        [class.flex-row-reverse]="!data.title"
      >
        <div
          class="flex flex-1 flex-col justify-center border-b border-gray-200 bg-white pl-4"
        >
          <h3
            class="text-base font-semibold leading-none text-gray-900"
            *ngIf="!!data.title"
          >
            {{ data.title }}
          </h3>
          <h4 class="pt-1 text-sm text-gray-600" *ngIf="!!data.subtitle">
            {{ data.subtitle }}
          </h4>
        </div>
        <fip-button
          data-cy="modal-close-btn"
          [backgroundColor]="'black'"
          [label]="'&#xd7;'"
          [labelTextClass]="'text-2xl font-bold'"
          [textColor]="'white'"
          [classes]="'p-4 ml-0 !w-16 !h-16 !rounded-none'"
          (click)="onNoClick()"
        ></fip-button>
      </div>
      <div #target></div>
    </div>
  </div>
</div>
