import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { ChartShape, LineChartDataPoints } from '@interfaces';
import { PlotlyService } from 'angular-plotly.js';

@Component({
  selector: 'fip-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() dataPoints: LineChartDataPoints[] = [];
  @Input() title = '';
  @Input() mode = 'lines+markers';
  @Input() xTitle = '';
  @Input() yTitle = '';
  @Input() width = 640;
  @Input() height = 400;
  @Input() xTicksVals?: number[];
  @Input() xTicksLabels?: string[];
  @Input() shapes: ChartShape[] = [];
  @Input() minYValue: number | undefined;
  @Input() maxYValue: number | undefined = 0;
  @Input() setAutoMinYValue = false;
  @Input() exportable = true;
  @Input() showFirstMarker = true;
  @Input() showLastMarker = true;
  @Input() markerSize = 3;
  @Input() tickAngle = 0;

  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly plotlyService = inject(PlotlyService);

  divId = 'plotly-data-graph';

  data: any[] = [];

  mainLineConfig = {
    type: 'scatter',
    mode: 'lines+markers',
    marker: { color: '#C0C0C0', size: 3 },
  };

  firstMarkerConfig = {
    mode: 'markers',
    name: 'Scatter',
    marker: { color: 'rgb(255, 200, 0)', size: 10 },
  };

  lastMarkerConfig = {
    mode: 'markers',
    name: 'Scatter',
    marker: { color: 'rgb(0, 0, 0)', size: 10 },
  };

  config = {
    scrollZoom: false,
    editable: false,
    staticPlot: false,
    responsive: true,
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'pan2d',
      'resetScale2d',
      'zoomOut2d',
      'zoomIn2d',
      'zoom',
      'toImage',
    ],
    displaylogo: false,
  };

  layout = {
    width: this.width,
    height: this.height,
    autosize: false,
    showlegend: false,
    title: '&nbsp;',
    shapes: [{}],
    xaxis: {
      title: {
        text: '',
        standoff: 20,
      },
      showgrid: false,
      showline: true,
      zeroline: false,
      fixedrange: true,
      ticks: 'inside',
      range: [0, 1],
      // tickmode: 'auto',
      ticktext: this.xTicksLabels,
      tickvals: this.xTicksVals,
      tickangle: 0,
    },
    yaxis: {
      title: {
        text: '',
        standoff: 10,
      },
      showgrid: false,
      showline: true,
      zeroline: false,
      fixedrange: true,
      ticks: 'inside',
      range: [0, 1],
    },
  };

  ngOnInit(): void {
    this.layout.title = this.title;
    this.layout.xaxis.title.text = this.xTitle;
    this.layout.yaxis.title.text = this.yTitle;
    this.refreshGraph();
  }

  refreshGraph() {
    this.data = [];
    // this.layout.xaxis.range = [0, this.dataPoints[0].x[0]]
    let xMaxValue = 0;
    let yMaxValue = 0;
    let yMinValue = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < this.dataPoints.length; i++) {
      const dataPoint = this.dataPoints[i];
      if (this.getMaxXaxisValue(dataPoint) > xMaxValue) {
        xMaxValue = this.getMaxXaxisValue(dataPoint);
      }
      if (this.getMaxYaxisValue(dataPoint) > yMaxValue) {
        yMaxValue = this.getMaxYaxisValue(dataPoint);
      }

      if (this.setAutoMinYValue) {
        if (this.getMinYaxisValue(dataPoint) < yMinValue) {
          yMinValue = this.getMinYaxisValue(dataPoint);
        }
      }

      this.data = [this.createMainLine(dataPoint), ...this.shapes];

      if (this.showFirstMarker) {
        this.data.push(this.createFirstMarker(dataPoint));
      }

      if (this.showLastMarker) {
        this.data.push(this.createLastMarker(dataPoint));
      }

      if (this.tickAngle) {
        this.layout.xaxis.tickangle = this.tickAngle;
      }
    }
    this.layout.xaxis.range = [0, xMaxValue];
    this.layout.yaxis.range = [
      this.minYValue ?? yMinValue ?? 0,
      Math.max(yMaxValue, this.maxYValue ?? 0),
    ];
    this.layout.xaxis.ticktext = this.xTicksLabels;
    this.layout.xaxis.tickvals = this.xTicksVals;
    this.layout.shapes = this.shapes;
  }

  private createMainLine(dataPoint: LineChartDataPoints) {
    return <Plotly.Data>{
      x: dataPoint.x,
      y: dataPoint.y,
      type: this.mainLineConfig.type,
      mode: this.mode || this.mainLineConfig.mode,
      hoverinfo: 'x+y',
      marker: {
        color: this.mainLineConfig.marker.color,
        size: this.markerSize || this.mainLineConfig.marker.size,
      },
    };
  }

  createFirstMarker(dataPoint: LineChartDataPoints) {
    return <Plotly.Data>{
      x: [dataPoint.x[0]],
      y: [dataPoint.y[0]],
      mode: this.firstMarkerConfig.mode,
      hovermode: false,
      hoverinfo: 'none',
      marker: {
        color: this.firstMarkerConfig.marker.color,
        size: this.lastMarkerConfig.marker.size,
      },
    };
  }

  createLastMarker(dataPoint: LineChartDataPoints) {
    return <Plotly.Data>{
      x: [dataPoint.x[dataPoint.x.length - 1]],
      y: [dataPoint.y[dataPoint.y.length - 1]],
      mode: this.lastMarkerConfig.mode,
      hovermode: false,
      hoverinfo: 'none',
      marker: {
        color: this.lastMarkerConfig.marker.color,
        size: this.lastMarkerConfig.marker.size,
      },
    };
  }

  getMaxXaxisValue(dataPoint: LineChartDataPoints) {
    let maxValue = Number.MIN_SAFE_INTEGER;

    for (let i = 0; i < dataPoint.x.length; i++) {
      if (Number.isFinite(dataPoint.x[i]) && dataPoint.x[i] > maxValue) {
        maxValue = dataPoint.x[i];
      }
    }

    return Math.ceil(maxValue * 1.05);
  }

  getMaxYaxisValue(dataPoint: LineChartDataPoints) {
    let maxValue = Number.MIN_SAFE_INTEGER;

    for (let i = 0; i < dataPoint.y.length; i++) {
      if (Number.isFinite(dataPoint.y[i]) && dataPoint.y[i] > maxValue) {
        maxValue = dataPoint.y[i];
      }
    }

    return Math.ceil(maxValue * 1.05);
  }

  getMinYaxisValue(dataPoint: LineChartDataPoints) {
    let minValue = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < dataPoint.y.length; i++) {
      if (Number.isFinite(dataPoint.y[i]) && dataPoint.y[i] < minValue) {
        minValue = dataPoint.y[i];
      }
    }

    return Math.floor(minValue * 0.95);
  }

  async handleExportClick() {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.divId);
    (await this.plotlyService.getPlotly()).downloadImage(graphDiv, {
      format: 'svg',
      filename: `export-${new Date().getTime()}`,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataPoints']) {
      this.refreshGraph();
      this._cdr.detectChanges();
    }

    if (changes['width']?.currentValue) {
      this.layout.width = changes['width'].currentValue;
    }
    if (changes['height']?.currentValue) {
      this.layout.height = changes['height'].currentValue;
    }
  }
}
