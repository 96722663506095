import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@frontend-workspace/app-config';
import { ApiResponse } from '@interfaces';
import { HotToastService } from '@ngneat/hot-toast';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorToastComponent } from '../components/error-toast/error-toast.component';

export enum ApiEndpointType {
  Server,
  Function,
}

interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUri: string;
  private baseUriFunction: string;

  constructor(
    private readonly _http: HttpClient,
    @Inject(APP_CONFIG) private readonly _appConfig: any,
    private readonly _toaster: HotToastService,
  ) {
    this.baseUri = this._appConfig.apiBaseUri;
    this.baseUriFunction = this._appConfig.azureFunctionsApiBaseUri;
  }

  private handleError<T>() {
    return (error: HttpErrorResponse): Observable<ApiResponse<T>> => {
      return of({
        data: null,
        error: true,
        errorDetails: {
          ...error,
          correlationId: 'some guid here',
          errors: [
            {
              code: 1001,
              message: 'This is the error message',
            },
            {
              code: 1020,
              message: 'This is another error message',
            },
          ],
        },
      });
    };
  }

  private constructHttpOptions(options?: HttpOptions) {
    // Set auth token in local storage to bypass authentication during end-to-end tests
    const token = window.localStorage.getItem('auth_token');
    if (token) {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      });
      return { ...options, headers };
    }
    return options;
  }

  public get<T>(
    resource: string,
    options?: HttpOptions,
    uriToUse: ApiEndpointType = ApiEndpointType.Server,
  ): Observable<ApiResponse<T>> {
    const baseUriToUse =
      uriToUse === ApiEndpointType.Server ? this.baseUri : this.baseUriFunction;

    const httpOptions = this.constructHttpOptions(options);

    return this._http.get<T>(`${baseUriToUse}/${resource}`, httpOptions).pipe(
      map((data) => ({ data })),
      catchError(this.handleError<T>()),
    );
  }

  public post<T, U>(
    resource: string,
    payload: U,
    uriToUse: ApiEndpointType = ApiEndpointType.Server,
  ): Observable<ApiResponse<T>> {
    const baseUriToUse =
      uriToUse === ApiEndpointType.Server ? this.baseUri : this.baseUriFunction;

    const httpOptions = this.constructHttpOptions({});

    return this._http
      .post<T>(`${baseUriToUse}/${resource}`, payload, httpOptions)
      .pipe(
        map((data) => ({ data })),
        catchError(this.handleError<T>()),
      );
  }

  public delete<T>(
    resource: string,
    options?: HttpOptions,
    uriToUse: ApiEndpointType = ApiEndpointType.Server,
  ): Observable<ApiResponse<T>> {
    const baseUriToUse =
      uriToUse === ApiEndpointType.Server ? this.baseUri : this.baseUriFunction;

    const httpOptions = this.constructHttpOptions(options);

    return this._http
      .delete<T>(`${baseUriToUse}/${resource}`, httpOptions)
      .pipe(
        map((data) => ({ data })),
        catchError(this.handleError<T>()),
      );
  }

  public put<T, U>(
    resource: string,
    payload: U,
    uriToUse: ApiEndpointType = ApiEndpointType.Server,
  ): Observable<ApiResponse<T>> {
    const baseUriToUse =
      uriToUse === ApiEndpointType.Server ? this.baseUri : this.baseUriFunction;

    const httpOptions = this.constructHttpOptions({});

    return this._http
      .put<T>(`${baseUriToUse}/${resource}`, payload, httpOptions)
      .pipe(
        map((data) => ({ data })),
        catchError(this.handleError<T>()),
      );
  }

  private displayToast(error: HttpErrorResponse): void {
    let toastMessage = `Server error. Click to see more.`;
    if (error.status === 400) {
      toastMessage = `Bad request`;
    }
    if (error.status === 401) {
      toastMessage = `Not found or missing required privileges`;
    }
    if (error.status === 403) {
      toastMessage = `Forbidden`;
    }
    if (error.status === 503) {
      toastMessage = `Service unavailable, retry in 2 min.`;
    }
    this._toaster.error(ErrorToastComponent, {
      data: {
        dialogTitle: `Error`,
        toastMessage,
        error,
      },
    });
  }
}
