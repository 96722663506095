import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search';
import {
  searchLoading,
  selectPlannedBomArticleSearchResult,
  selectSavedPlannedBomArticles,
} from '@core-app/state/search/search.selectors';
import { selectCompaniesByArticleId } from '@core-app/state/search';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { ArticleSearchResult, Company, ProductionNumber } from '@interfaces';
import { Store } from '@ngrx/store';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'fip-production-planned-bom-modal',
  templateUrl: './production-planned-bom.modal.html',
  styleUrls: ['./production-planned-bom.modal.scss'],
})
export class ProductionPlannedBomModalComponent implements OnDestroy {
  readonly modalKey: ModalKey = 'production_planned_bom';
  readonly displayedColumns = ['batch'];
  readonly columns = [
    {
      index: 'batch',
      label: 'Batch',
      key: 'batchId' as keyof ArticleSearchResult,
    },
  ];
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);
  private readonly _router = inject(Router);

  searchFormGroup = new FormGroup({
    articleNumber: new FormControl('', [Validators.required]),
    companyCode: new FormControl('', [Validators.required]),
  });
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: ProductionNumber[];
  savedArticles$ = this._store.select(selectSavedPlannedBomArticles);
  companies$: Observable<Company[] | null> = of([]);

  constructor() {
    // Disable form group while loading...
    this.searchLoading$.pipe(takeUntilDestroyed()).subscribe((loading) => {
      if (loading) {
        this.searchFormGroup.disable();
      } else {
        this.searchFormGroup.enable();
      }
    });
  }

  getCompanies(articleNumber?: string) {
    if (!articleNumber || articleNumber.length < 8) {
      return;
    }

    this.companies$ = this._store.select(
      selectCompaniesByArticleId(articleNumber),
    );

    this.companies$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((companies) => {
        if (companies) {
          if (!this.searchFormGroup.get('companyCode')?.value) {
            this.searchFormGroup
              .get('companyCode')
              ?.setValue(companies[0].code);
          }
        }
      });

    this._store.dispatch(SearchActions.getCompanies({ articleNumber }));
  }

  submit(article?: string, company?: string) {
    this.searchResult = undefined;

    // If we are selecting an item from the saved article IDs list
    if (article) {
      this.searchFormGroup.get('articleNumber')?.setValue(article);
      this.getCompanies(article);
    }

    // If we are selecting an item from the saved company codes list
    if (company) {
      this.searchFormGroup.get('companyCode')?.setValue(company);
    }

    const { articleNumber, companyCode } = this.searchFormGroup.value;

    if (!this.searchFormGroup.valid) {
      return;
    }

    let companyName = '';

    this.companies$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((companies) => {
        if (companies) {
          const firstCompany = companies?.filter(
            (company) => company.code === companyCode,
          )[0];
          companyName = firstCompany.name || '';

          // Listen to search result from ngrx store
          this._store
            .select(
              selectPlannedBomArticleSearchResult(articleNumber, companyCode),
            )
            .pipe(
              takeUntilDestroyed(this._destroyRef),
              tap((data) => {
                if (!data) {
                  if (articleNumber && companyCode) {
                    this._store.dispatch(
                      SearchActions.loadPlannedBomArticle({
                        articleNumber,
                        searchType: this.modalKey,
                        companyCode,
                        companyName,
                      }),
                    );
                  }
                }
              }),
              tap((data) => {
                if (data) {
                  if (articleNumber && companyCode) {
                    this._store.dispatch(
                      SearchActions.reorderSearchResults({
                        searchType: 'plannedBomArticleSearchResults',
                        payload: {
                          articleNumber,
                          companyCode,
                          companyName,
                        },
                      }),
                    );
                  }
                }
              }),
            )
            .subscribe((data) => {
              this.searchResult = data;
              const { articleNumber, companyCode } = this.searchFormGroup.value;

              if (data?.length) {
                this._router.navigate([
                  `/production/stock/${articleNumber}/${companyCode}`,
                ]);
              }
            });
        }
      });
  }

  ngOnDestroy() {
    // Clear search results before closing modal
    this.searchResult = undefined;

    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
