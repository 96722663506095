import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class LoginGuard {
  constructor(
    private readonly _authService: MsalService,
    private readonly _router: Router,
  ) {}

  canActivate(): boolean {
    const accounts = this._authService.instance.getAllAccounts();
    if (accounts?.length) {
      this._authService.instance.setActiveAccount(accounts[0]);
      this._router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
