import { Injectable, inject } from '@angular/core';

import {
  Alert,
  InsertEmailRecipientsDTO,
  Monitor,
  PortalNotification,
  Recipient,
  RecipientGroup,
  RecipientGroupType,
} from '@interfaces';
import { ApiService } from './api.service';
import { ApiEndpointType } from '@frontend-workspace/shared/src/lib/services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AlertingService {
  private readonly _api = inject(ApiService);

  getMonitorsByType(monitorType?: MonitorType) {
    return this._api.get<Monitor[]>(
      `monitors/type/${monitorType}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getMonitorById(id: string) {
    return this._api.get<Monitor>(
      `monitors/${id}`,
      {},
      ApiEndpointType.Function,
    );
  }

  upsertMonitors(baseMonitor: Monitor) {
    const monitor: Monitor = {
      ...baseMonitor,
      partitionKey: 'v1',
    };

    return this._api.post(`monitors`, monitor, ApiEndpointType.Function);
  }

  deleteMonitor(id: string) {
    return this._api.delete(`monitors/${id}`, {}, ApiEndpointType.Function);
  }

  executeMonitor(id: string) {
    return this._api.post(
      `execute-monitors/id/`,
      { rowKey: id },
      ApiEndpointType.Function,
    );
  }

  getRecipientGroups(groupType: RecipientGroupType) {
    return this._api.get<RecipientGroup[]>(
      `recipient-groups/${groupType}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getRecipientsFromGroup(groupId: string) {
    return this._api.get<Recipient[]>(
      `recipients/${groupId}`,
      {},
      ApiEndpointType.Function,
    );
  }

  upsertEmailRecipients(dto: InsertEmailRecipientsDTO) {
    return this._api.post(`recipients/`, dto, ApiEndpointType.Function);
  }

  createNewRecipientGroup(dto: RecipientGroup) {
    return this._api.post<RecipientGroup[], RecipientGroup>(
      `recipient-groups/`,
      dto,
      ApiEndpointType.Function,
    );
  }

  deleteRecipientGroup(groupId: string) {
    return this._api.delete<RecipientGroup[]>(
      `recipient-groups/${groupId}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getNotifications(email: string) {
    return this._api.get<PortalNotification[]>(
      `notifications`,
      { params: { email: email } },
      ApiEndpointType.Function,
    );
  }

  getAlerts() {
    return this._api.get<Alert[]>(`alerts`, {}, ApiEndpointType.Function);
  }

  getAlertById(id: string) {
    return this._api.get<Alert>(`alerts/${id}`, {}, ApiEndpointType.Function);
  }

  updateAlertDataById(id: string) {
    return this._api.put<Alert, unknown>(
      `alerts/${id}/data`,
      {},
      ApiEndpointType.Function,
    );
  }

  approveAlertById(id: string) {
    return this._api.put<Alert, unknown>(
      `alerts/${id}/state/ready`,
      {},
      ApiEndpointType.Function,
    );
  }

  deleteAlertById(id: string) {
    return this._api.delete<string>(
      `alerts/${id}`,
      {},
      ApiEndpointType.Function,
    );
  }
}

/**
 * Define monitor types and translate the type to a readable name
 * @returns An array of children objects if found, otherwise an empty array
 */

export type MonitorType =
  | 'non_confirming_parts_leaving_supplier'
  | 'reduction_shipment_size'
  | 'downtime_above_x_minutes'
  | 'master_pedigree_change'
  | 'periodic_inspection_or_calibration_failure'
  | 'missing_data'
  | 'reduction_of_oee_below_x_percent'
  | 'increase_in_ng_rate_above_x_percent'
  | 'spc_level_out_of_control'
  | 'raw_material_below_x_percent'
  | 'calibration_periodic_test_inspection_out_of_specification'
  | 'daily_production_target_missed_by_x_percent'
  | 'failure_during_incoming_inspection';

export const MONITOR_NAMES: Record<MonitorType, string> = {
  non_confirming_parts_leaving_supplier:
    'Non Confirming Parts Leaving Supplier',
  reduction_shipment_size: 'Reduction in Shipment Size',
  downtime_above_x_minutes: 'Downtime Above X Minutes',
  master_pedigree_change: 'Master Pedigree Change',
  periodic_inspection_or_calibration_failure:
    'Periodic Inspection or Calibration Failure',
  missing_data: 'Missing Data',
  reduction_of_oee_below_x_percent: 'Reduction of OEE Below X Percent',
  increase_in_ng_rate_above_x_percent: 'Increase in NG Rate Above X Percent',
  spc_level_out_of_control: 'SPC Level Out of Control',
  raw_material_below_x_percent: 'Raw Material Below X Percent',
  calibration_periodic_test_inspection_out_of_specification:
    'Calibration Periodic Test Inspection Out of Specification',
  daily_production_target_missed_by_x_percent:
    'Daily Production Target Missed by X Percent',
  failure_during_incoming_inspection: 'Failure During Incoming Inspection',
};

export function createNewReductionInShipmentSizeFormGroup(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required]),
    recipientGroupId: new FormControl('', [Validators.required]),
    shipmentId: new FormControl('', [Validators.required]),
    edi: new FormControl(0, [Validators.required]),
  });
}

export function createNewDailyProductionTargetMissedFormGroup(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required]),
    recipientGroupId: new FormControl('', [Validators.required]),
    productionId: new FormControl('', [Validators.required]),
    productionTarget: new FormControl(0, [Validators.required]),
  });
}
