import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './app.actions';
import { PartsSummaryDTO } from '@interfaces';

export const APP_FEATURE_KEY = 'app';

export interface SelectedPartData {
  serialNumber: string;
  selectedProductionOrderId: string;
}

export interface AppState {
  selectedPart: SelectedPartData;
  singleItemsByProductionOrder: { [key: string]: PartsSummaryDTO[] | null };
  singleItemsByProductionOrderLoading: boolean;
}

const initialAppState: AppState = {
  selectedPart: <SelectedPartData>{},
  singleItemsByProductionOrder: {},
  singleItemsByProductionOrderLoading: false,
};

const reducer = createReducer(
  initialAppState,
  on(AppActions.selectPart, (state, { selectedPart }) => {
    return {
      ...state,
      selectedPart: selectedPart,
    };
  }),
  on(
    AppActions.fetchSingleItemsByProductionOrderSuccess,
    (state, { items, productionOrderId }) => {
      const singleItems = { ...state.singleItemsByProductionOrder };

      // Sort by externalSingleItemId alphabetically
      let newItems = [] as PartsSummaryDTO[];

      if (items) {
        newItems = [...items];

        newItems = newItems?.sort(
          (a, b) =>
            a.externalSingleItemId?.localeCompare(b.externalSingleItemId || ''),
        );
      }
      singleItems[productionOrderId] = newItems;

      return {
        ...state,
        singleItemsByProductionOrder: singleItems,
      };
    },
  ),
  on(
    AppActions.toggleSingleItemsByProductionOrderLoading,
    (state, { loading }) => {
      return {
        ...state,
        singleItemsByProductionOrderLoading: loading,
      };
    },
  ),
);

export function appReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action);
}
