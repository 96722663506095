import { Injectable, inject } from '@angular/core';
import {
  ApiResponse,
  ArticlePayload,
  ArticleSearchResult,
  SerialPayload,
  SerialSearchResult,
} from '@interfaces';
import { ApiService } from '@frontend-workspace/shared/src/lib/services/api.service';
import { formatToDate } from '@helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private readonly _apiPath = '';
  private readonly _api = inject(ApiService);

  searchArticle(payload: ArticlePayload) {
    return this._api.get<ArticleSearchResult[]>(
      `batches?articleNumber=${
        payload.articleNumber
      }&productionFrom=${formatToDate(
        payload.productionFrom,
      )}&productionTo=${formatToDate(payload.productionUntil)}`,
    );
  }

  searchSerialNumber(
    payload: SerialPayload,
  ): Observable<ApiResponse<SerialSearchResult>> {
    return this._api.get<SerialSearchResult>(
      `single-items?serialNumber=${payload.serialNumber}`,
    );
  }
}
