import { Injectable } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { FeatureFlagsService } from '@core-app/services/feature-flags.service';
import { productArea } from '@interfaces';
import { Observable } from 'rxjs';

// Guard to prevent users from accessing routes that are not enabled for them
@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
  ) {}

  canLoad(
    route: Route,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const feature = route.data?.['feature'] satisfies productArea;

    if (feature) {
      const isEnabled =
        this.featureFlagsService.getFeatureAreaVisibility(feature) ===
          'enabled' ||
        this.featureFlagsService.getFeatureVisibility(feature) === 'enabled';

      if (isEnabled) {
        return true;
      }
    }
    this.router.navigate(['/dashboard']);
    return false;
  }

  canActivate(
    route: Route,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const feature = route.data?.['feature'] satisfies productArea;

    if (feature) {
      const isEnabled =
        this.featureFlagsService.getFeatureAreaVisibility(feature) ===
          'enabled' ||
        this.featureFlagsService.getFeatureVisibility(feature) === 'enabled';

      if (isEnabled) {
        return true;
      }
    }
    this.router.navigate(['/dashboard']);
    return false;
  }
}
